import React from 'react'
import image35 from '../assets/images/scan_identity_4798748_4003625_1__4_360.gif'


const Verification = () => {
	
  return (
    <>
      <section class="section gradient-banner" style={{background:"#fff",padding:"50px 0 170px"}}>

        <div class="containerxd-xl">
          <div class="row align-items-center">
            <div class="col-xs-6 col-sm-6 col-md-7 col-lg-6 order-2 order-md-1 text-center text-md-left">
              
            <div className='rounded bg-white' style={{backgroundColor:"#f3f3f3"}}>
              <h1 class="text-blck font-weight-bold mb-4">
              Varifie 
              </h1>
              <p class="text-blck mb-5">
              Varifie is a cutting-edge identity management solution that leverages the power of blockchain technology and artificial 
              intelligence to provide unparalleled security and convenience for businesses and consumers alike. 
              With just a few clicks, stakeholders can store, verify, and manage their identities with ease, eliminating the 
              challenges and complexities that have traditionally plagued the identity management landscape.<br/>
              At the heart of Varifie's platform is its innovative use of machine learning and deep learning algorithms, which enable it to accurately recognize and authenticate unique identities in real time. This, in turn, ensures that fraud prevention is a top priority, providing businesses with the peace of mind they need to focus on what they do best.<br />
              Moreover, with blockchain technology powering its security protocols, Varifie takes advantage of the most advanced encryption methods available, ensuring that sensitive data remains private and secure at all times. As a result, it is ideally suited for a wide range of business practices, from finance and insurance to marketplace and education.<br />
              With Varifie, businesses can rest assured that their identity management needs are being handled with the utmost care and efficiency, freeing up valuable time and resources to focus on other critical areas of their operations. And for consumers, it offers a simple and secure way to manage their identities, making it an essential tool for anyone looking to take control of their digital identity in today's fast-paced, interconnected world.
              </p>
            </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-5 col-lg-6 text-center order-1 order-md-2">
              <div className='image24'>
              <img class="img-fluid" src={image35} alt="screenshot" />
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default Verification
