import React, { useState, useEffect, Component } from "react";
import images12 from '../assets/images/download.png'
import { Link, useNavigate, NavLink, useLocation } from "react-router-dom";
import whitePaper from '../assets/images/FuFi-Whitepaper.pdf';
import menu from "../assets/images/menu.png"
import close from "../assets/images/close.png"


//import $ from "jquery";
const Navigationbar = () => {
  const [navSize, setnavSize] = useState("10rem");
  const [navColor, setnavColor] = useState("transparent");
  const [positions, setPositions] = useState("relative");
  const [padinds, setPadinds] = useState("10px solid #ffffff");

  //   $('.navbar-toggler').click(function(){
  //      $(".navbar-nav").toggleClass("mobile-nav");
  //      $(this).toggleClass("is-active");
  //   });

  const [navbarnav, setNavbarnav] = useState("")
  const [disnone, setDisnone] = useState("disnoneClas")
  const [disnone21, setDisnone21] = useState("disnoneClas21")

  const [ptop, setPtop] = useState("-12px")

  const menutoggleCls = () => {
    if (navbarnav === navbarnav) {
      setNavbarnav("mobile-nav")
      setDisnone(disnone21)
      setDisnone21(disnone)
    }
  }

  const menutoggleCls21 = () => {
    setNavbarnav("")
    setDisnone(disnone21)
    setDisnone21(disnone)
  }
  const listenScrollEvent = () => {
    window.scrollY > 10 ? setnavColor("#fff") : setnavColor("transparent");
    window.scrollY > 10 ? setnavSize("8rem") : setnavSize("10rem");
    window.scrollY > 10 ? setPositions("fixed") : setPositions("relative");
    window.scrollY > 10 ? setPadinds("0") : setPadinds("10px solid transparent");
    window.scrollY > 10 ? setPtop("-12px") : setPtop("2px");
  };
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  const [openmenu, setOpenmenu] = useState("openClass");
  const [enab, setEnab] = useState(false);
  const [enabs, setEnabs] = useState(false);
  const [text, setText] = React.useState();




  // const handleClick = () => {
  //   const location = useLocation();
  //   return location.pathname; 
  // } 

  return (
    <>
      <nav class="navbar main-nav navbar-expand-lg px-2 px-sm-0 py-2 py-lg-0" style={{ position: positions, borderTop: padinds, borderBottom: padinds, transition: "all 1s" }}>
        <div class="containerxd-xl">
          <NavLink className="navLnksd" to="/" style={{ paddingTop: "7px" }}>
            <img src={images12} alt="logo" style={{ backgroundColor: navColor, maxWidth: navSize, transition: "all 1s" }} />
          </NavLink>
          {/* <button class="navbar-toggler" type="button" style={{backgroundColor:"#fc0"}}>  </button> */}


          <div class="navbar-collapse" id="navbarNav">


            <ul class="navbar-nav ml-auto" id={navbarnav}>
              <div class="navbar-toggler menu-toggle" id={disnone} style={{ marginTop: ptop }} onClick={menutoggleCls}>
                <img src={menu} />
              </div>
              <div class="navbar-toggler menu-toggle" id={disnone21} style={{ marginTop: ptop }} onClick={menutoggleCls21}>
                <img src={close} />
              </div>
              <li class="nav-item">
                <a class="nav-link dropdown-toggle" href="/" data-toggle="dropdown">Home
                  <span><i class="ti-angle-down"></i></span>
                </a>

              </li>
              {/* <li class="nav-item">
                <a class="nav-link" href="/aboutus">About</a>
              </li> */}


              {/* <li class="nav-item">
                <a class="nav-link" href="https://web.fufi.info/" target="_top"> Eco System</a>
              </li> */}

              {/* <li class="nav-item dropdown">

                <div class="nav-link" onClick={() => setEnab(!enab)}>
                  Eco System
                  <span>  <i class="fa fa-angle-down" aria-hidden="true"></i></span>
                </div>

                <ul className={enab ? "xsubMnu sub-menu-mob" : "dropdown-menu"} >
                  <li><a href="/TradeKia">TradeKia</a></li>
                  <li><a href="/FufiedgeWallet">Fufi Edge</a></li>
                  <li><a href="/Trade0x">Trade0x</a></li>
                  <li><a href="/CryptoxIN">CryptoxIN</a></li>
                  <li><a href="/TradeBRO">TradeBRO</a></li>
                  <li><a href="varifie">Varifie</a></li>
                </ul>
              </li>*/}

              <li class="nav-item dropdown">
                <div class="nav-link" onClick={() => setEnabs(!enabs)}>Updates
                  <span>  <i class="fa fa-angle-down" aria-hidden="true"></i></span>
                </div>
                <ul className={enabs ? "xsubMnu sub-menu-mob" : "dropdown-menu"} >
                  <li><a target="_blank" href="https://content.fufi.info/announcements/press-release/">Press Release</a></li>
                  <li><a target="_blank" href="https://content.fufi.info/announcements/">Announcements</a></li>
                  {/* <li><a target="_blank" href="https://www.youtube.com/channel/UC8t6N4uzi_CjA54oKCyWG0Q">Videos</a></li> */}
                  
                  <li><a href="/Timeline">Timeline</a></li>
                </ul>

              </li>



              <li class="nav-item">
                <a class="nav-link" href="/metaverse">
                  Metaverse & NFTials
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" target="_blank" href="https://content.fufi.info/">
                  Blog
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" target="_blank" href="https://fufiscan.com/">
                  FUFI Explorer
                </a>
              </li>
              {/* <li class="nav-item">
              <a class="nav-link" href="/app-ads">
                  app-ads
                </a>
              </li> */}

              <li class="nav-item mr-2" id="bigBts1" >
                <NavLink class="nav-link" id="bigBts2" target="_blank"
                  to="SID">Become SID</NavLink>
              </li>

              <li class="nav-item mr-2" id="bigBts1" >
                <a class="nav-link" href='https://wp.fufi.info/' id="bigBts2" target="_blank" >White Paper</a>
              </li>

              <li class="nav-item" id="bigBts1" >
                <a class="nav-link" href='https://strategypaper.fufi.info/' id="bigBts2" target="_blank" >Strategy Paper</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navigationbar


