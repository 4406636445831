import React , {useState,useEffect} from "react";
//import { PieChart } from 'react-minimal-pie-chart';
//import Chart from './Chart'
import './Token.css';
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import PropTypes from "prop-types";

function Token() {
 
const pieData = {
  showInLegend: true,
	datasets: [{
    data: [35,15,7,7,4,30],
    fontSize:['150'],
		backgroundColor: [
      'rgba(167, 0, 0, 0.8)',
      'rgba(255,123,123, 0.8)',
      'rgba(255,82,82, 0.8)',
      'rgba(255,123,123, 0.8)',
      'rgba(197, 122, 122, 0.9)',
      'rgba(226, 57, 57, 0.8)'
   
		],
    hoverOffset:50,    
    startAngle:  90,
    transformStyle: '3d'
	}],
  labels: [
		'Burnt Supply 35%',
		'Liquidity 15%',
		'Marketing 7%',
    'Advisor’s Pool 7%',
    'Airdrop to BasedETH Holders 4%',
    'EBP, SID and misc rewards - 30%'
	]
};
 
    return (
 
                <>
              <Chart type="pie"  data={pieData} />
                </>
 
    )


}

Token.propTypes = {
  name: PropTypes.string.isRequired,
  age: PropTypes.number.isRequired,
  occupation: PropTypes.string.isRequired
};

Token.defaultProps = {
  name: "Nathan",
  age: 27,
  occupation: "Software Developer"
};
  
export default Token;