import React, { useState, useEffect } from 'react'
import mobile from '../assets/images/fufiBack1.svg'
import tech12 from '../assets/images/tech12.png'
import tech13 from '../assets/images/tech13.png'
import whyus from '../assets/images/why-us.png';
import img21 from '../assets/images/21.png';
import img22 from '../assets/images/22.png';
import img23 from '../assets/images/23.png';
import img24 from '../assets/images/24.png';
import img25 from '../assets/images/25.png';
import img26 from '../assets/images/26.png';
import Testimonials from "../container/testimonial/Testimonials"
//import TestiMoniald from '../container/TestiMonials/TestiMonials';
import Newroadmap from "../container/roadmap/newroadmap"
import boxed1 from '../assets/images/boxed1.png';
import boxed2 from '../assets/images/boxed2.png';

import Token from "../container/chart/Token";
import "../container/chart/Token.css";
import whitelogo5 from '../assets/images/timesOfIndia.png';
import whitelogo from '../assets/images/whitelogo.svg';
import anilogo from '../assets/images/ani_logo.jpg';
import fullresolution from '../assets/images/fullresolution.png';
import axios from 'axios';
import Carousel from "nuka-carousel";
import loaders from "../assets/images/loaders.gif"
import ldersimg1 from "../assets/images/fufi-burn1.png"
import ldersimg2 from "../assets/images/fufi-burn2.png"
import ldersimg3 from "../assets/images/fufi-burn.png"
import { Helmet } from "react-helmet";
import newspaper from "../assets/images/newspaper.png"
import listed from "../assets/images/listed.svg"
import NewandMedia from "../container/NewandMedia"
import NewandMedias from "../container/NewandMedias"
//import AOS from "aos";
// import "aos/dist/aos.css";
// AOS.init({
//     duration: 1200
// });


const Homepage = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [posts, setPosts] = useState([]);
    const [postdt, setPostdt] = useState([]);
    const [postds, setPostds] = useState([]);
    const [isLoading1, setIsLoading1] = useState(true);
    const [isLoading2, setIsLoading2] = useState(true);

    const [slideCount, setSlideCount] = useState(2);
    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
        getHitoryData()
    }, []);

    useEffect(() => {
        getHitoryData2()
    }, []);

    useEffect(() => {
        getHitoryData3()
    }, []);

    const getHitoryData = async () => {
        setIsLoading1(true)
        var config = {
            method: 'post',
            url: 'http://167.71.237.201:8555/getalluserc',
            headers: {}
        };

        await axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                let res = response.data.data;
                console.log("R33 Users", res);
                setPosts(res)
                setIsLoading1(false)
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    const getHitoryData2 = async () => {
        setIsLoading2(true)
        var config = {
            method: 'GET',
            url: 'https://api.fufi.info/getAllsids',
            headers: {}
        };

        await axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                let res = response.data.data.length;
                console.log("Total SIDs", res);
                setPostdt(res)
                setIsLoading2(false)
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    const getHitoryData3 = async () => {
        setIsLoading(true);
        var config = {
            method: 'GET',
            url: 'https://fufiscan.com/api?module=account&action=listaccounts&page=1&offset=10000',
            headers: {}
        };
        await axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                let res = response.data.result.length;
                console.log("Total Fufi holders", res);
                setPostds(res)
                setIsLoading(false)
            })
            .catch(function (error) {
                console.log(error);
            });

    }


    function renderLeftNav(onClick, disabled) {
        return (
            <button
                type="button"
                className="image-gallery-left-nav"
                aria-label="Prev Slide"
                disabled={disabled}
                onClick={onClick}
            >
                {/* <FaArrowLeft size={30} color="#000" /> */}
            </button>
        );
    }

    function renderRightNav(onClick, disabled) {
        return (
            <button
                type="button"
                className="image-gallery-right-nav"
                aria-label="Next Slide"
                disabled={disabled}
                onClick={onClick}
            >
                {/* <FaArrowRight size={30} color="#000" /> */}
            </button>
        );
    }

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      };

    return (
        <>

            <Helmet>
                <title>FutureFi</title>
                <meta name="description" content="Future Finance is a decentralized blockchain for the modern era. It combines WEB 3.0 & AI to provide decentralized applications, systems, and communities with unparalleled security and sustainability." />
                <meta name="keywords" content="About US"></meta>
            </Helmet>





            <section class="section gradient-banner">
                <div class="containerxd-xl">
                    <div class="row align-items-center">
                        <div class="col-xs-12 col-sm-12 col-md-7 col-lg-6 order-2 order-md-1 text-center text-md-left">

                            <h1 class="text-blck font-weight-bold mb-4">
                                Building a bridge to the Future of Finance
                            </h1>
                            <p class="text-blck mb-5">
                                Future Finance Is A Decentralized Blockchain For The Modern Era. It Combines Cutting-Edge Technologies To Provide Decentralized Applications, Systems, And Communities With Unparalleled Security And Sustainability.
                                <br />
                                FutureFi Blockchain Combines Artificial Intelligence And Machine Learning With Proof Of Authority, Proof Of Burnt Stake, And Delegated Proof Of Stake To Provide A Fair, Transparent, And Scalable Platform Capable Of Hosting A Variety Of Decentralised Applications For Real-World Use Cases.
                            </p>

                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-5 col-lg-6 text-center order-1 order-md-2">
                            <img class="img-fluid" src={mobile} alt="screenshot" />
                        </div>
                    </div>
                </div>
            </section>

            <section class="section gradient-banner position-relative">

                <div class="containerxd-xl">
                    <div class="row align-items-center">
                        <div class="col-xs-6 col-sm-6 col-md-5 col-lg-5 text-center order-1">
                            <div className='bg-whpds'>
                                <img class="img-fluid" src={tech12} alt="screenshot" />
                            </div>
                        </div>
                        <div class="col-xs-6 col-sm-6 col-md-7 col-lg-7 order-2 order-md-1 text-center text-md-left">
                            <div className='rounded shadow p-52 bg-white'>
                                <h1 class="text-blck font-weight-bold mb-4">
                                    FutureFi Technical Framework
                                </h1>
                                <p class="text-blck mb-5">
                                    FutureFi Is Powered By A Robust Technology Framework That Makes It Highly Differentiated And The Best Blockchain Solution For Business Operations.
                                </p>
                                <ul>
                                    <li>High Transaction Speed (250K TPS At The Moment With A Target Of 1 Million TPS).</li>
                                    <li>Powered By Artificial Intelligence That Enables The Fragmented And Parallel Processing Of Transactions Across A Multi-Chain Network.</li>
                                    <li>Our Platform runs of proof of burnt stakes consensus.</li>
                                    <li>Future Finance Only Takes 10 Sec To Form A New Block, Thus Reducing Network Congestion.</li>
                                    
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section class="section gradient-banner position-relative">

                <div class="containerxd-xl">
                    <div class="row align-items-center">

                        <div class="col-xs-6 col-sm-6 col-md-7 col-lg-7 order-2 order-md-2 text-center text-md-left">
                            <div className='rounded shadow p-52 bg-white'>
                                <h1 class="text-blck font-weight-bold mb-4">
                                    The FutureFi Consensus Mechanism
                                </h1>
                                <p class="text-blck mb-5">
                                    One Of The Reasons Why FutureFi Is The Most Scalable Blockchain Platform Is Its Backend Consensus Algorithm. Here Is A Glimpses, How The FutureFi Ecosystem Works.
                                </p>
                                <ul>
                                    <li>Every Network Is Run By Its Chosen Block Validators And Only They Have The Right To Verify And Seal Transactions.</li>
                                    {/* <li>FuFI Holds The Right To Add 12 Validators To The Network Out Of Which 7 Will Be Run In - House By Our Team.</li>
                                    */}
                                    <li>FuFi Plans To Implement Its Current Architecture In Two Phases - Mainnet 1.0 Named FuFI Edge Node And Mainnet 2.0 FuFi Edge+ Node.</li>
                                </ul>

                            </div>
                        </div>


                        <div class="col-xs-6 col-sm-6 col-md-5 col-lg-5 order-1 order-md-2">
                            <div className='bg-whpds'>
                                <img class="img-fluid" src={tech13} alt="screenshot" />
                            </div>
                        </div>

                    </div>
                </div>
            </section>



            <section class="section gradient-banner position-relative">
                <div class="containerxd-xl">
                    <h2 className='headingd'>Why Us ?</h2>
                    <p class="coinmics">
                        Reasons to choose FutureFi
                    </p>
                    <div class="row">

                        <div class="d-nonblock col-sm-6 col-md-12 col-lg-4 order-2 order-md-2 text-center text-md-left">
                            <div className='rounded p-52'>
                                <div className='fufiimgx'>
                                    <img src={whyus} />
                                </div>
                            </div>
                        </div>


                        <div class="col-xs-6 col-sm-6 col-md-12 col-lg-8 order-1 order-md-2">
                            <div className='bg-whpds'>

                                <Testimonials />
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <section class="section gradient-banner position-relative">
                <div class="containerxd-xl">
                    <h2 className='headingd'>Features</h2>
                    <p class="coinmics">
                        FutureFi is equipped with simple yet crucial features that make it one of the best blockchain platforms available.
                    </p>
                    <div class="row">
                        <div class="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                            <div class="bxshadow">
                                <div class="img-box">
                                    <img src={img21} alt="Fufi"/>
                                </div>

                                <div class="cardbx first-card border-0">

                                    <div class="card-body" style={{ padding: '28px 10px 20px' }}>
                                        <h3 class="card-title">Secured</h3>
                                        <p class="card-text">Multi-layered technical architecture and Proof of Stake (PoS) for a high-level protection layer for DApps.</p>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                            <div class="bxshadow">
                                <div class="img-box">
                                    <img src={img22} alt="Fufi"/>
                                </div>
                                <div class="cardbx second-card border-0">

                                    <div class="card-body" style={{ padding: '28px 10px 20px' }}>
                                        <h3 class="card-title">Scalable</h3>
                                        <p class="card-text">On Fufi main chain recording only relevant data by verifying itself from the bitcoin blockchain and/or the timestamp. Distributes the core’s power into fragments.</p>
                                    </div>
                                </div>


                            </div>
                        </div>

                        <div class="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                            <div class="bxshadow">
                                <div class="img-box">
                                    <img src={img23} alt="Fufi"/>
                                </div>
                                <div class="cardbx border-0">

                                    <div class="card-body" style={{ padding: '28px 10px 20px' }}>
                                        <h3 class="card-title">Fast</h3>
                                        <p class="card-text">With increasing data and nodes to be implemented, the TPS of Fufi main chain goes upwards instead of falling down.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                            <div class="bxshadow">
                                <div class="img-box">
                                    <img src={img24} alt="Fufi"/>
                                </div>
                                <div class="cardbx first-card border-0">
                                    <div class="card-body" style={{ padding: '28px 10px 20px' }}>
                                        <h3 class="card-title">Transparent</h3>
                                        <p class="card-text">We strongly support the data privacy and with the help of the
                                            blockchain in technology,FutureFi confidently keeps the promise.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                            <div class="bxshadow">
                                <div class="img-box">
                                    <img src={img25} alt="Fufi"/>
                                </div>
                                <div class="cardbx second-card border-0">
                                    <div class="card-body" style={{ padding: '28px 10px 20px' }}>
                                        <h3 class="card-title">Diversity</h3>
                                        <p class="card-text">Designed to stay relevant for all business arena and use cases. The versatility of tech framework makes the Fufi blockchain obvious as a perfect business solution.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                            <div class="bxshadow">
                                <div class="img-box">
                                    <img src={img26} alt="Fufi"/>
                                </div>
                                <div class="cardbx border-0">
                                    <div class="card-body" style={{ padding: '28px 10px 20px' }}>
                                        <h3 class="card-title">Decentralised</h3>
                                        <p class="card-text">FutureFi is working an ecosystem which is distributed in nature and non-centralised in functionality.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>








            <section class="section gradient-banner position-relative">
                <div class="containerxd-xl">
                    <h2 className='headingd'>Coinomics</h2>
                    <p class="coinmics2">
                        All the operations within the Future Finance DApps are fueled by its native
                        coin FuFi. Users and participants can use the FuFi coin to execute all the activities
                        within the Future Finance ecosystem and across all our partner platforms. The total
                        supply is 650 Million FuFi Coins.<br /><br />
                    </p>
                

                    <div class="row" style={{ alignItems: "center", justifyContent: "center" }}>
                        <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7">
                            <div class="row">
                              
                                <div class="col-6 col-md-6 col-lg-6 paddingLfts2">

                                    <div class="colMdrs2 aos-init aos-animate">
                                        <div class="whyus_text fortokensd tok2">
                                            <h4 class="whyus_text">Burnt Supply in POA version of FUFI</h4>
                                            35% (230 million)</div>
                                    </div>

                                </div>
                                <div class="col-6 col-md-6 col-lg-6 col-lg-6">

                                    <div class="colMdrs2 aos-init">
                                        <div class="whyus_text fortokensd tok3">
                                            <h4 class="whyus_text">Liquidity </h4>
                                            15% (100 million)</div>

                                    </div>
                                </div>
                                <div class="col-6 col-md-6 col-lg-6 paddingLfts2">

                                    <div class="colMdrs2 aos-init">
                                        <div class="whyus_text fortokensd tok4">
                                            <h4 class="whyus_text">Marketing </h4>
                                             7% (45 million)</div>
                                    </div>

                                </div>
                                <div class="col-6 col-md-6 col-lg-6">

                                    <div class="colMdrs2 aos-init">
                                        <div class="whyus_text fortokensd tok5">
                                            <h4 class="whyus_text">Advisor’s Pool</h4>
                                            7% (45 million)</div>

                                    </div>
                                </div>
                                <div class="col-6 col-md-6 col-lg-6 paddingLfts2">

                                    <div class="colMdrs2 aos-init">
                                        <div class="whyus_text fortokensd tok6">
                                            <h4 class="whyus_text">Airdrop to BasedETH Holders</h4>
                                            4% (30 million)</div>

                                    </div>
                                </div>
                                <div class="col-6 col-md-6 col-lg-6">

                                    <div class="colMdrs2 aos-init">
                                        <div class="whyus_text fortokensd tok6">
                                            <h4 class="whyus_text">EBP, SID and misc rewards</h4>
                                            30% (200 million)</div>

                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5">
                            <div className='canvasInd'>
                                <Token />
                            </div>

                        </div>

                    </div>

 <p>
 <b>Note-</b>unused coins from the marketing and advisor pool will be  burnt.
Marketing and advisor coins will be locked as well upon distribution we will release TGE details
 </p>


                </div>
            </section>






            <section class="section gradient-banner position-relative" style={{paddingBottom:'5px'}}>
                <div class="containerxd-xl">
                    <h2 className='headingd'>Roadmap</h2>
                    <p class="coinmics3 coinx">
                        We Have Planned Wonderful Roadmap For You.
                    </p>
                    <div class="rowds" style={{overflowY:'hidden'}}>

                        <Newroadmap />
                    </div>
                </div>
            </section>


            <section class="section gradient-banner position-relative">
                <div class="containerxd-xl">
                    <h2 className='headingd'>Easy Trade and swap - FUFI</h2>

                    <div className='row'>
                        <div className='col-xxl-6 col-xl-6 col-sm-6 col-md-6 col-xs-6'>
                            <div class="bg-whpds whbpxd12">
                                <img className='easyTrade' src={listed} />
                            </div>
                        </div>

                        <div className='col-xxl-6 col-xl-6 col-sm-6 col-md-6 col-xs-6'>
                            <div className='originalClassnew'>
                            <NewandMedias />
                            </div>
                        </div>
                    </div>
                </div>
            </section >


            <section class="section gradient-banner position-relative">
                <div class="containerxd-xl">
                    <div className='row'>



                        <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12
col-xs-12'>

                            <div class="head">
                                <div class="headerobjectswrapper">
                                    <h2 className='headingd'>News and Media</h2>
                                </div>

                                <div class="subhead">
                                    <span><img src={newspaper} /> </span>
                                    FUTURE'S FINANCE</div>
                            </div>
                            <div class="content">
                                <div class="collumns">

                                    <NewandMedia />

                                    {/* <ImageGallery
                                        items={images}
                                        value={title}
                                        slideInterval={2500}
                                        lazyLoad="false"
                                        showBullets="true"
                                        showPlayButton="false"
                                        autoPlay="true"
                                        itemsToShow="2"
                                        renderRightNav={renderRightNav}
                                        renderItem={(item) => (
                                            <a href={item.href} target='_blank'>
                                                <img src={item.original} alt={item.originalAlt} />
                                                <h4 className='nmedia'>{item.description}</h4>
                                            </a>
                                        )}
                                        renderThumbInner={(tg) => (
                                            <a href={tg.href} target='_blank'>
                                                <span className="image-gallery-thumbnail-inner">
                                                    <img className="image-gallery-thumbnail-image" src={tg.thumbnail} />
                                                    <div class="image-gallery-thumbnail-label">{tg.description}</div>
                                                </span>
                                            </a>)}
                                    /> */}







                                </div>

                            </div>

                        </div>




                    </div>
                </div>
            </section>










            {/*<section class="section gradient-banner position-relative">
                <div class="containerxd-xl">
                    <h2 className='headingd'>Exchanges Listed on</h2>

                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10 offset-md-1 offset-lg-1">

                            <div class="flex-container border-curve">

                                <div className='divBxs'>
                                    <div className='bifixes'>
                                        <a href="https://tradekia.com/" target="_blank">
                                            <img src={boxed1} />
                                        </a>
                                    </div>
                                </div>


                                <div className='divBxs'>
                                    <div className='bifixes'>
                                        <a href="https://m.indoex.io/trade/FUFI_USDT" target="_blank">
                                            <img src={boxed2} />
                                        </a>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </section>*/
            }


            {/*<section class="section gradient-banner position-relative">
                <div class="containerxd-xl">
                    <h2 className='headingd'>News and Media</h2>

                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10 offset-md-1 offset-lg-1">

                            <div class="flex-container border-curve">

                                <div className='divBxs'>
                                    <div className='colmd3' style={{ padding: "0" }}>
                                        <a href="https://timesofindia.indiatimes.com/business/india-business/top-10-most-influential-business-leaders-of-2022-by-the-indian-alert/articleshow/95666278.cms" target="_blank">
                                            <img src={whitelogo5} />
                                        </a>
                                    </div>
                                </div>


                                <div className='divBxs'>
                                    <div className='colmd3'>
                                        <a href="https://www.deccanherald.com/brandspot/pr-spot/future-s-finance-a-proof-of-authority-blockchain-platform-forays-into-nfts-and-metaverse-1097479.html" target="_blank">
                                            <img src={whitelogo} />
                                        </a>
                                    </div>
                                </div>


                                <div className='divBxs'>
                                    <div className='colmd3'>
                                        <a href="https://edtimes.in/futures-finance-a-new-age-blockchain-platform-is-utilizing-the-poa-algorithm-to-deliver-scalable-real-life-solutions/" target="_blank">
                                            <img src={fullresolution} />
                                        </a>
                                    </div>
                                </div>

                                <div className='divBxs'>
                                    <div className='colmd3'>
                                        <a href="https://www.aninews.in/news/business/business/futures-finance-launches-its-proprietary-crypto-wallet-the-fufi-edge-available-for-download-on-the-android-play-store20220412152908/" target="_blank">
                                            <img src={anilogo} />
                                        </a>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>
</section>*/}


            <section class="section gradient-banner position-relative ">
                <div class="containerxd-xl">
                    <h2 className='headingd pb-3' style={{ paddingTop: "5rem" }}>Our Users</h2>

                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10 offset-md-1 offset-lg-1">

                            <div class="flex-container border-curve">

                                <div className='divBxsdf xy1'>
                                    <div className='colmd33' style={{ padding: "0" }}>
                                        <a target="_blank">
                                            Total Fufi holders
                                        </a>
                                        <span className='ldersimg1'>
                                            <img src={ldersimg1} />
                                        </span>
                                    </div>
                                    {isLoading ? (
                                        <span>
                                            <img src={loaders} />
                                        </span>
                                    ) : (
                                        <>
                                            <span>{postds.toLocaleString('en')}</span>
                                        </>
                                    )}

                                </div>


                                {/* <div className='divBxsdf xy2'>
                                    <div className='colmd33'>
                                        <a>
                                            R33 Users
                                        </a>
                                        <span className='ldersimg1'>
                                            <img src={ldersimg2} />
                                        </span>
                                    </div>
                                    {isLoading1 ? (
                                        <span>
                                            <img src={loaders} />
                                        </span>
                                    ) : (
                                        <>
                                            <span>{posts.toLocaleString('en')}</span>
                                        </>
                                    )}


                                </div> */}


                                <div className='divBxsdf xy3'>
                                    <div className='colmd33'>
                                        <a>
                                            Total SID
                                        </a>
                                        <span className='ldersimg1'>
                                            <img src={ldersimg3} />
                                        </span>
                                    </div>
                                    {isLoading2 ? (
                                        <span>
                                            <img src={loaders} />
                                        </span>
                                    ) : (
                                        <>
                                            <span>{postdt.toLocaleString('en')}</span>
                                        </>
                                    )}
                                </div>





                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
    
}


export default Homepage
