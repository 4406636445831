import React, { useEffect } from "react";
import Styled from "styled-components";
import Parallax from "parallax-js";
import PropTypes from "prop-types";

import backgroundImage from "../../assets/images/download2.png";

//import textImage from "../../assets/images/download7.png";
import astronautImage from "../../assets/images/download1.png";
//import planet1Image from "../../assets/images/download3.png";
import planet2Image from "../../assets/images/download4.png";
import rocketImage from "../../assets/images/download7.png";



const Container = Styled.div`
	width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
	overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
`;

const Metaverse2 = ({ backgroundImage }) => {

    
const layers = [
    {
      name: "astronaut",
      image: astronautImage,
      dataDepth: "2"
    },
    {
      name: "planet2",
      image: planet2Image,
      dataDepth: "-1"
    },
    {
      name: "rocket",
      image: rocketImage,
      dataDepth: "-8"
    }
  ];
  useEffect(() => {
    const scene = document.getElementById("scene");
    new Parallax(scene);
  });

  return (
    <Container backgroundImage={backgroundImage}>
      <div id="scene">
        {layers.map((l, index) => (
          <img
            key={index}
            data-depth={l.dataDepth}
            src={l.image}
            alt={l.name}
          />
        ))}
      </div>
    </Container>
  );
};

Metaverse2.propTypes = {
  backgroundImage: PropTypes.string,
  layers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      image: PropTypes.string,
      dataDepth: PropTypes.string
    })
  )
};

Metaverse2.defaultProps = {
  backgroundImage: backgroundImage,
 // layers: layers
};

export default Metaverse2;
