import React from 'react'

const NonReundable = () => {
  return (
    <>
      <section class="section gradient-banner" style={{ background: "#fff", padding: "50px 0 170px" }}>

        <div class="containerxd-xl">
          <div class="row align-items-center">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">

              <div className='rounded shadow p-52 bg-white' style={{ backgroundColor: "#f3f3f3" }}>
                <h1 class="text-blck font-weight-bold mb-4">
                  NON-Refundable Policy
                </h1>

                <p className="text-blck mb-3">
                  Future Finance a sub-brand of Nineteen Real Tech Labs Pvt Ltd, under no circumstances, entertains the refund or cancellation of any successfully fulfilled order or digital assets. Once the user placed an order which has been partially or fully matched the amount in the chosen currency is immediately transferred to the seller’s account and these transactions cannot be reversed.
                  You are responsible for ensuring, you stay up-to-date with the policy, and for periodically visiting our Website and this Policy to check for any changes that can occur at any time without prior notice.
                </p>

                <p className="text-blck">
                  The purpose of this policy is to outline the terms and conditions under which no refunds will be issued for transactions conducted with fiat currency or cryptocurrency.
                </p>
                <h2 className='text-blck font-weight-bold mt-2'>Policy:</h2>
                <p className="text-blck">

                  All transactions conducted with fiat currency or cryptocurrency are considered final and non-refundable.


                  Once a transaction is initiated, it cannot be cancelled or reversed, and no refunds will be issued under any circumstances.


                  This policy applies to all transactions, including but not limited to purchases of goods and services, donations, and investments.


                  The customer or client is responsible for ensuring the accuracy of all transaction details, including the amount, currency, and recipient information, before initiating the transaction.


                  The company or organization reserves the right to cancel or refuse any transaction at its discretion, without providing a reason or explanation.


                  In the event of a technical error or malfunction that results in the loss of funds or assets, the company or organization will make every effort to rectify the issue, but is not liable for any losses incurred by the customer or client.


                  This policy may be updated or amended at any time, without prior notice to customers or clients.


                </p>

              </div>
            </div>




          </div>
        </div>
      </section>




    </>
  )
}

export default NonReundable
