import React from 'react'
//import Backgroundjs from './Backgroundjs'
//import Slider from './Slider'
import Nftials3 from '../assets/images/Nftials4.svg'
import backgame from '../assets/images/background.svg'
import globalFin from '../assets/images/globalFin.svg'
import meta from '../assets/images/metaverse.svg'
import artificialintelligence from '../assets/images/artificialintelligence.svg'
import Metaverse2 from '../container/metaverse/Metaverse2'

const Metaverse = () => {
  return (
    <>







<section class="section sectionBack" style={{paddingTop:"80px"}}>

<div class="containerxd-xl">
                    <div class="row align-items-center">
              
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div className='rounded shadow p-52 bg-white' 
                        style={{marginTop: "2.5rem", height: "100%", paddingTop: "6rem !important", paddingBottom: "8rem !important"}}>
                        <h1 class="text-blck font-weight-bold mb-4">
                            Metaverse
                            </h1>
                            <p class="text-blck mb-5">
                            As An Organization, We Are Committed To Upholding The History And Traditions Of Our Country. In That Context, We Aim To Deliver An Immersive Experience Through Metaverse Virtual Reality Wherein People From All Corners Of The World Will Have The Capability To Experience Vedic Bharat In Its Truest Sense. Moreover, They Will Be Able To Interact On Multiple Levels With Each Other In The Exact Cultural And Geographical Setup Of An Indian Traditional Destination, Albeit Virtually. In This Context, Understanding The Metaverse Will Be Important.
                              
                                <br />
                                While Our Metaverse Virtual Reality Will Enable People To Gain Rich Travel Experiences In An Immersive Environment, We Do Have Other Applications As Well. We Believe, The FuFi Metaverse Will Be Extremely Relevant In The Highly Crippled Real-Estate Industry. Most Importantly, Customers Will Have The Option To Take Virtual Tours Of Properties Irrespective Of Which Corner Of The World Those Are Located In. Another Potent Application Of Our Metaverse Would Be The Education Industry And Corporate Sector. As We Continue To Battle With The Pandemic, We Believe, The Best-Case Scenario In The Far-Fetched Future Would Be The Hybrid Model Of Working Or Learning. The Fufi Metaverse Stock Will Have Other Relevant Use Cases As Well.
                            </p>
                            </div>
                        </div>
                        </div>
                        </div>

             


<Metaverse2 />
              
</section>




          
            <section class="section pt-0 position-relative mobileDxs">

<div class="containerxd-xl">
    <div class="row align-items-center">
        <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 text-center order-1">
            <div className='bg-whpds'>
                <img class="img-fluid" src={Nftials3} alt="screenshot" />
            </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 order-2 order-md-1 text-center text-md-left">
            <div className='rounded shadow p-52 bg-white'>
                <h1 class="text-blck font-weight-bold mb-4">
                NFTials
                </h1>
                <p class="text-blck mb-5">
                The NFT Market Is Booming. NFTials Will Be A Full-Fledged NFT Marketplace That Will Have The Capability To Host All Kinds Of NFT-Related Activities. Moreover, Our Primary Goal With This Is To Create Non-Fungible Tokens That Will Represent Prominent And Talented Personalities Who Have Achieved Laurels In Their Respective Fields. Here, The Objective Is To Uphold Their Talent And Contributions In Front Of The World Through The Power Of Blockchain, Crypto, And NFT.
                    <br />
                    Let’s Say You Are A Gaming Enthusiast And In This Case, You Are Extremely Interested In A Game That Features Your Favourite Athlete. Our Platform Can Completely Optimize Your Overall Gaming Experience As Compared To What You Would Have Probably Had In A Traditional Game. Here Is How. NFTials Will Enable You To Play While Impersonating As Your Favourite Athlete - Buy/Rent His/Her Jersey, Buy/Rent His/Her Shoes, Buy/Rent His/Her Sporting Gears, All Of Which Will Be Listed On Our Platform As NFT Representations And You Will Be Able To Take Temporary Or Permanent Ownership In The Form Of NFTs. This Is How We Are Harnessing The Power Of Non-Fungible Tokens With The Sole Objective Of Improving Your Experience And Delight Factor.
                </p>
            </div>
        </div>
    </div>
</div>
</section>



<section class="section pt-0 position-relative" style={{padding: "32px 0"}}>

<div class="containerxd-xl">
    <div class="row align-items-center">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 order-1">
        <div className='foxyed' style={{background:'transparent',boxShadow:'none'}}>

<p className='gamingstxt' style={{padding:"1em 0em"}}>
Gaming is one of the many applications of NFTials and we will roll those out in due course of time.
 </p>

</div>
            </div>
            </div>
            </div>
            <div className='gamings'>
     <img src={backgame} />
 </div>
</section>



   
<section class="section pt-0 position-relative">
            <div className='gamings24'>
     <img src={globalFin} />
 </div>
                <div class="containerxd-xl">
                    <div class="row align-items-center">

                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 order-2 order-md-2 text-center text-md-left">
                        <div className='rounded shadow p-52 bg-white mrGinsdf'>
                                <h1 class="text-blck font-weight-bold mb-4" style={{fontSize:"30px"}}>
                                Revolutionizing the Future of Global Finance
                                </h1>
                                <p class="text-blck mb-5">
                                A Decentralized Autonomous Plant Backed By Cutting-Edge Technologies Like Artificial Intelligence And Built On The Basis Of Next-Gen Blockchain Technology.
Equipped With The Power To Scale Itself (In Terms Of Speed) With Increasing Load (Of Transactions) On The Main Chain.
                                    <br />
                                    Designed For The Seamless Development Of Web 3.0 Applications.<br/>
                                    Designed For A Myriad Of Real-Life Applications That Can Be Executed In A Highly Scalable Manner.<br/>
                                    Designed To Process 2.5 Lakh Transactions Per Second With A Block Interval Of 15 Secs.<br/>
                                    Cheapest Blockchain Solution In The Industry.<br/>
                                    Supports Solidity And Several Other Side Chain Applications.
                                </p>
                            </div> 
                        </div>


                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 order-1 order-md-2">
                        <div className='mrGinsdfTopd'>
                                <h1 class="text-blck font-weight-bold mb-4" style={{visibility:"hidden",fontSize:"5rem"}}>
                              .
                                </h1>
                                </div>


                        <div className='rounded shadow p-52 bg-white mrGinsdf'>
                                <h1 class="text-blck font-weight-bold mb-4" style={{fontSize:"30px"}}>
                                Scope of Practical Implementations
                                </h1>
                                <p class="text-blck mb-5">
                                FuFi Is Service Ready Blockchain And Runs With Practical Implementation Such As Defi Smart Contracts, Staking, NFTs, Metaverse And More.
                                    <br />
                                    FuFi Will Design A Robust Metaverse To Offer Virtual Spaces For A Variety Of Practical Applications - Real Estate, Travel, Education, Corporate, And More.<br/>
                                    FuFi Will Be Equipped With An NFT-Based Platform Named NFTials. Here, We Will Host Various Digital Representations In The Form Of NFTs Which Users Can Rent/Buy To Engage In Various Kinds Of Applications Like Gaming.  
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>



   



            <section class="section gradient-banner">

<div class="containerxd-xl">
<div class="row">
                                <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7">

                            
                            <div class="row">
                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 paddingLfts">

                                    <div class="colMdrs aos-init aos-animate">
                                        <div class="whyus_text fortokensd tok1">
                                        Powered By Artificial Intelligence That Enables The Fragmented And Parallel Processing Of Transactions Across A Multi-Chain Network.
                                           </div>
                                    </div>

                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 paddingLfts">

                                    <div class="colMdrs aos-init aos-animate">
                                        <div class="whyus_text fortokensd tok2">
                                        High Transaction Speed (250K TPS At The Moment With A Target Of 1 Million TPS)
                             </div>
                                    </div>

                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 paddingLfts">

                                    <div class="colMdrs aos-init">
                                        <div class="whyus_text fortokensd tok3">
                                        Future Finance Only Takes 10 Sec To Form A New Block, Thus Reducing Ne Twork Congestion.
                                         </div>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 paddingLfts">

                                    <div class="colMdrs aos-init">
                                        <div class="whyus_text fortokensd tok4">
                                        Our Platform Runs On The Proof Burnt Stakes Consensus Algorithm.
                             </div>
                                    </div>

                                </div>
                            
                            </div>
                       
                            </div> 
                            
                            
                            <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5">
                            <div class="gamingd">
                        <img src={artificialintelligence} />
                        </div>
                                </div>      
                            
                            </div>
               


                        
                
                </div>
              
            </section>

      
    </>
  )
}

export default Metaverse
