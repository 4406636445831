import React from 'react'
import tradekia from '../assets/images/tradekia.gif'


const Tradekiya = () => {
  return (
    <>
      <section class="section gradient-banner" style={{background:"#fff",padding:"50px 0 170px"}}>

        <div class="containerxd-xl">
          <div class="row align-items-center">
            <div class="col-xs-6 col-sm-6 col-md-7 col-lg-6 order-2 order-md-1 text-center text-md-left">
              
            <div className='rounded shadow p-52 bg-white' style={{backgroundColor:"#f3f3f3"}}>
              <h1 class="text-blck font-weight-bold mb-4">
                TradeKia
              </h1>
              <p class="text-blck mb-5">
                An Indian Native Discount Derivative Exchange. With a wide range of advanced features, TradeKia is arguably the best cryptocurrency exchange and the best cryptocurrency trading platform. Most importantly, it is a crypto exchange with lowest fees.

              </p>

              <p class="text-blck mb-5">
              Zero Trading Fee on the closing side for loss-making trade.
                <br />
                10 levels of Trading Fee Commission over affiliate mechanisms.
                <br/>
                A Derivative exchange where Futures of current financial products will be traded.
                <br/>
                One-stop destination for lifelong FinTech Crypto Products.
                <br/>
                Tax Compliant exchange for Indian Users.
              </p>
              <div className='buttontrd'>
                  <a href="https://tradekia.com/" target="_blank">TradeKia</a>
                </div>
            </div>
            </div>



            <div class="col-xs-6 col-sm-6 col-md-5 col-lg-6 text-center order-1 order-md-2">
              <img class="img-fluid" src={tradekia} alt="screenshot" />
            </div>
          </div>
        </div>
      </section>




    </>
  )
}

export default Tradekiya
