import React, {useRef} from 'react'
//import { Carousel } from '3d-react-carousal';
import './testimonial3.css'
//import MyComponent from '3d-react-carousel'
//import '3d-react-carousel/dist/index.css'
import Carousel from 'react-elastic-carousel'


const Testimonials = () => {

   // let x1 = [
   //    '',
   // ];
   // let x2 = [
   //    '',
   // ];
   // let x3 = [
   //    '',
   // ];
   // let x4 = [
   //    '',
   // ];
   // let x5 = [
   //    '',
   // ];
   // let x6 = [
   //    '',
   // ];
   // let x7 = [
   //    '',
   // ];
   // let x8 = [
   //    '',
   // ];

   // let slides = [
   //    <>
   //       <span>{x2}</span>,
   //       <span>{x1}</span>
   //    </>,
   //    <>
   //       <span>{x4}</span>
   //       <span>{x3}</span>
   //    </>,
   //    <>
   //       <span>{x6}</span>
   //       <span>{x5}</span>
   //    </>,
   //    <>
   //       <span>{x8}</span>
   //       <span>{x7}</span>
   //    </>
   // ];
   // let slides =
   //    [
   //       <>
   //       {x2}
   //          {x1}
   //       </>
   //       ,
   //       <>
   //       {x4}
   //          {x3}
   //       </>
   //       ,
   //       <>
   //       {x6}
   //          {x5}
   //       </>
   //       ,
   //       <>
   //       {x8}
   //          {x7}
   //       </>
   //    ];

const items = [
  {
   id:"Completely Decentralized",
   title:"This Essentially Means That Our DApp Is Built In A Manner That Does Not Require Anyone To Know Or Trust Anybody Else In The Network As All The Operations, Transactions, Etc. Happen Through A Distributed Ledger And Would Require The Consensus Of The Majority. Future Finance Follows This Protocol To The Core."
},
  {
   id:"Immutable Smart Contract",
   title:"Future Finance Is Equipped With An Immutable Smart Contract That Forms The Foundation Of All Activities Within Our Platform. This Essentially Means That The Smart Contract Will Enable The Occurrence Of An Event On The Completion Of Certain Pre-Coded Activities Under Any Circumstances."
  },     
  {
   id:"Peer to Peer Transactions",
   title:"Future Finance Enables Seamless Peer To Peer Transactions Without The Need For Any Third-Party Interference. As We Are Completely Decentralized In Nature, The Transactions Do Not Depend On Anyone Apart From The Parties Involved, Thus Making It Much More Secure And Low-Cost As Compared To The Non-Peer To Peer Model."
  },     
  {
   id:"Blockchain as a Service",
   title:"Future Finance Is A Service-Ready Blockchain And Is Equipped To Deliver Various Practical Use Cases Involving DeFi Smart Contracts, Staking Modules, And NFTs (Via The NFTials Platform)."
  },    
]
const carouselRef = useRef(null);
let resetTimeout;

   return (
      <>  
{/* <Carousel itemsToShow={1} enableAutoPlay interval={1} infiniteLoop={true}> */}

<Carousel
className='Carousel45'
 ref={carouselRef}
 pagination={true}
 enableAutoPlay={true}
 showArrows={false}
 autoPlaySpeed={4000}
 isRTL={false}
 onNextEnd={({ index }) => {
     clearTimeout(resetTimeout);
     resetTimeout = setTimeout(() => {
         carouselRef?.current?.goTo(0);
     }, 4000); // same time
 }}>
{items.map(item =>
<div key={item.id}>
   <div className='bidBoxify'>
   <div className='bidBoxify25'>
      <h5>{item.id}</h5>
 <p>{item.title}</p>
   </div>
   </div>
</div>
   )}
</Carousel>



{/*

const itemsPerPage = 3
const items = [...]
const carouselRef = useRef(null);
const totalPages = Math.ceil(items.length / itemsPerPage)
let resetTimeout;

<Carousel
    ref={carouselRef}
    enableAutoPlay
    autoPlaySpeed={1500} // same time
    onNextEnd={({ index }) => {
         clearTimeout(resetTimeout)
         if (index + 1 === totalPages) {
            resetTimeout = setTimeout(() => {
               carouselRef.current.goTo(0)
           }, 1500) // same time
         }
    }}
    itemsToShow={itemsPerPage}
>
{items.map(() => )} 
</Carousel> */}


      </>
   )
}

export default Testimonials
