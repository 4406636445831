import React, {useState} from 'react'

import '../roadmap/testimonial.css'
//import mapsd from '../../assets/images/location.gif'
//import mapsd from '../assets/images/placeholder.png'
import boxed1 from '../../assets/images/boxed24.svg'
import boxed2 from '../../assets/images/boxed2.svg'
import boxed3 from '../../assets/images/boxed3.svg'
import boxed4 from '../../assets/images/boxed4.svg'

const Newroadmap = () => {

    const [activeTab, setActiveTab] = useState('tab1');

    const handleTabClick = (tab) => {
      setActiveTab(tab);
    };
  

    return (
        <>



            <section class="ps-timeline-sec">
            <div className='tabsNames'>
        <button onClick={() => handleTabClick('tab1')} className={activeTab === 'tab1' ? 'active' : ''}>
        Road map 2024
        </button>
        <button onClick={() => handleTabClick('tab2')} className={activeTab === 'tab2' ? 'active' : ''}>
        Road map 2023
        </button>
      </div>
      <div className='tabsBoxes'>
        {activeTab === 'tab1' && <>
        <div class="container">
                    <ol class="ps-timeline">
                        <li className='psTimeline'>
                            <div class="img-handler-top">
                                <img src={boxed1} />
                            </div>
                            <div class="ps-bot">
                                <ul>
                                <li>FUFI2.0 Revamp of FUFI Ecosystem</li>
                                    <li>Reduction of supply by 10 B to 650 Million coins only</li>
                                    <li>Deplpoyment of FUFI Nodes for EBPs</li>
                               
                                </ul>
                            </div>
                            <span class="ps-sp-top top1">Q1-2024</span>
                        </li>
                        <li className='psTimeline'>
                            <div class="img-handler-bot" style={{ right: "-50%" }}>
                                <img src={boxed2} />
                            </div>
                            <div class="ps-top">
                              
                                <ul>
                                <li>Deployment of L2 Bridge to Base Ecosystem</li>
                                    <li>Listing of FUFI against ETH at UNISWAP and Sharkswap</li>
                                    <li>Onboard 100 Kolls and twitter shillers</li>
                                </ul>
                            </div>
                            <span class="ps-sp-bot top2">Q2-2024</span>
                        </li>
                        <li className='psTimeline launched'>
                            <div class="img-handler-top">
                                <img src={boxed3} />
                            </div>
                            <div class="ps-bot">
                                <ul>
                                <li>Development of DEX launchpad Protocol TRADE0X for both base and fufi</li>
                                    <li>Launch of TradeoX</li>
                                    <li>CEX listings (HTX , KUCOIN, MEXC, BYBIT)</li>

                                </ul>
                             
                            </div>
                            <span class="ps-sp-top top3">Q3-2024</span>
                        </li>
                        <li className='psTimeline psTimne'>
                            <div class="img-handler-bot" style={{ left: "0%" }}>
                                <img src={boxed4} />
                            </div>
                            <div class="ps-top">
                            
                                <ul>
                                <li>Marketing continues</li>
                                    <li>Staking nodes for trending Pools of ALTs</li>

                                </ul>
                            </div>
                            <span class="ps-sp-bot top4">Q4-2024</span>
                        </li>
                    </ol>
                </div>
            </>}
        {activeTab === 'tab2' && <>
        <div class="container">
                    <ol class="ps-timeline">
                        <li className='psTimeline'>
                            <div class="img-handler-top">
                                <img src={boxed1} />
                            </div>
                            <div class="ps-bot">
                                <ul>
                                    <li>Launch of FUFI Blockchain</li>
                                    <li>Listing of FUFI Coin on Exchange</li>
                                    <li>Launch Of AMM TradeBRO</li>
                                    <li>Launch Of FUFI Smart Contract</li>
                               
                                </ul>
                            </div>
                            <span class="ps-sp-top top1">Q1-2022</span>
                        </li>
                        <li className='psTimeline'>
                            <div class="img-handler-bot" style={{ right: "-50%" }}>
                                <img src={boxed2} />
                            </div>
                            <div class="ps-top">
                              
                                <ul>
                                    <li>Launch Of FUFI Proprietary Wallet “FUFIEdge”</li>
                                    <li>Development Of Derivative Exchange Platform “TradeKIA”</li>
                                    <li>Development of CryptoxIN</li>
                                </ul>
                            </div>
                            <span class="ps-sp-bot top2">Q2-2022</span>
                        </li>
                        <li className='psTimeline launched'>
                            <div class="img-handler-top">
                                <img src={boxed3} />
                            </div>
                            <div class="ps-bot">
                                <ul>
                                    <li>Launch Of SID Module</li>
                                    <li>Launch Of Derivative Exchange Platform “TradeKIA”</li>
                                    <li>Development Of Decentralized Exchange “TradeOX”</li>
                                    <li>Launch Of Decentralized Exchange“TradeOX”</li>
                                    <li>Launch Of CryptoxIN (An Exclusive Social Community App)</li>

                                </ul>
                             
                            </div>
                            <span class="ps-sp-top top3">Q3-2023</span>
                        </li>
                        <li className='psTimeline'>
                            <div class="img-handler-bot" style={{ left: "0%" }}>
                                <img src={boxed4} />
                            </div>
                            <div class="ps-top">
                            
                                <ul>
                                    <li>Development Of Staking Module.</li>
                                    <li>Liquidity Protocol Launch.</li>
                                    <li>Launching Of Staking Module.</li>
                                    <li>Development & Launch Of NFT Platform.</li>
                                    <li>Launch Of NFT Marketplace NFTials In Dubai.</li>
                                    <li>Introduces Varifie</li>



                                </ul>
                            </div>
                            <span class="ps-sp-bot top4">Q4-2023</span>
                        </li>
                    </ol>
                </div>
            </>}
      </div>
        </section>










            {/* <section class="ps-timeline-sec">
                <div class="container">
                    <ol class="ps-timeline">
                        <li className='psTimeline'>
                            <div class="img-handler-top">
                                <img src={boxed1} />
                            </div>
                            <div class="ps-bot">
                                <ul>
                                <li>FUFI2.0 Revamp of FUFI Ecosystem</li>
                                    <li>Reduction of supply by 10 B to 650 Million coins only</li>
                                    <li>Deplpoyment of FUFI Nodes for EBPs</li>
                               
                                </ul>
                            </div>
                            <span class="ps-sp-top top1">Q1-2024</span>
                        </li>
                        <li className='psTimeline'>
                            <div class="img-handler-bot" style={{ right: "-50%" }}>
                                <img src={boxed2} />
                            </div>
                            <div class="ps-top">
                              
                                <ul>
                                <li>Deployment of L2 Bridge to Base Ecosystem</li>
                                    <li>Listing of FUFI against ETH at UNISWAP and Sharkswap</li>
                                    <li>Onboard 100 Kolls and twitter shillers</li>
                                </ul>
                            </div>
                            <span class="ps-sp-bot top2">Q2-2024</span>
                        </li>
                        <li className='psTimeline launched'>
                            <div class="img-handler-top">
                                <img src={boxed3} />
                            </div>
                            <div class="ps-bot">
                                <ul>
                                <li>Development of DEX launchpad Protocol TRADE0X for both base and fufi</li>
                                    <li>Launch of TradeoX</li>
                                    <li>CEX listings (HTX , KUCOIN, MEXC, BYBIT)</li>

                                </ul>
                             
                            </div>
                            <span class="ps-sp-top top3">Q3-2024</span>
                        </li>
                        <li className='psTimeline'>
                            <div class="img-handler-bot" style={{ left: "0%" }}>
                                <img src={boxed4} />
                            </div>
                            <div class="ps-top">
                            
                                <ul>
                                <li>Marketing continues</li>
                                    <li>Staking nodes for trending Pools of ALTs</li>

                                </ul>
                            </div>
                            <span class="ps-sp-bot top4">Q4-2024</span>
                        </li>
                    </ol>
                </div>
            </section> */}


            {/* <div class="demo">

                
                
                <div class="main-timeline20">
                    
                    <div class="timeline" data-heading="2011">
                        <div class="timeline-content">
                            <p class="description">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cupiditate doloribus repellat temporibus vitae voluptate.
                            </p>
                            <a href="#" class="read-more">read more</a>
                        </div>
                        <span class="year">
                            <span>2011</span>
                        </span>
                    </div>
 
                    <div class="timeline" data-heading="2012">
                        <div class="timeline-content">
                            <p class="description">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cupiditate doloribus repellat temporibus vitae voluptate.
                            </p>
                            <a href="#" class="read-more">read more</a>
                        </div>
                        <span class="year">
                            <span>2012</span>
                        </span>
                    </div>
 
                    <div class="timeline bottom" data-heading="2015">
                        <span class="year">
                            <span>2015</span>
                        </span>
                        <div class="timeline-content">
                            <p class="description">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cupiditate doloribus repellat temporibus vitae voluptate.
                            </p>
                            <a href="#" class="read-more">read more</a>
                        </div>
                    </div>
 
                    <div class="timeline bottom" data-heading="2016">
                        <span class="year">
                            <span>2016</span>
                        </span>
                        <div class="timeline-content">
                            <p class="description">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cupiditate doloribus repellat temporibus vitae voluptate.
                            </p>
                            <a href="#" class="read-more">read more</a>
                        </div>
                    </div>
                </div>
            
            
   
</div> */}


            {/* <section class="ps-timeline-sec">
                <div class="container">
                    <ol class="ps-timeline">
                        <li>
                            <div class="img-handler-top">
                                <img src={mapsd} />
                            </div>
                            <div class="ps-bot">
                                <div class="whyus_text fortokens tok6" style={{ backgroundColor: 'tranparent' }}>
                                    <ul className='bilsimg'>
                                        <li style={{ height: 'auto' }}>Launch of FUFI Blockchain</li>
                                        <li style={{ height: 'auto' }}>Listing of FUFI Coin on Exchange</li>
                                        <li style={{ height: 'auto' }}>Launch Of AMM TradeBRO</li>
                                        <li style={{ height: 'auto' }}>Launch Of FUFI Smart Contract</li>
                                    </ul>
                                </div>
                            </div>
                            <span class="ps-sp-top">Q1-2022</span>
                        </li>
                        <li>
                            <div class="img-handler-bot">
                                <img src={mapsd} />
                            </div>
                            <div class="ps-top">
                                <div class="whyus_text fortokens tok6" style={{ backgroundColor: 'tranparent' }}>
                                    <ul className='bilsimg'>
                                        <li style={{ height: 'auto' }}>Launch Of FUFI Proprietary Wallet “FUFIEdge”</li>
                                        <li style={{ height: 'auto' }}>Development Of Derivative Exchange Platform “TradeKIA”</li>
                                        <li style={{ height: 'auto' }}>Development of CryptoxIN</li>	
                                    </ul>
                                    </div>
                            </div>
                            <span class="ps-sp-bot">Q2-2022</span>
                        </li>
                        <li>
                            <div class="img-handler-top">
                                <img src={mapsd} />
                            </div>
                            <div class="ps-bot">
                                <div class="whyus_text fortokens tok6" style={{ backgroundColor: 'tranparent' }}>
                                    <ul className='bilsimg'>
                                        <li style={{ height: 'auto' }}>Launch Of SID Module</li>
                                        <li style={{ height: 'auto' }}>Launch Of Derivative Exchange Platform “TradeKIA”</li>
                                        <li style={{ height: 'auto' }}>Development of Decentralized Exchange “TradeOX”</li>
                                        <li style={{ height: 'auto' }}>Launch Of Decentralized Exchange“TradeOX”</li>
                                        <li style={{ height: 'auto' }}>Launch of CryptoxIN (An Exclusive Social Community App)</li>
                                    </ul></div>
                            </div>
                            <span class="ps-sp-top">Q3-2022</span>
                        </li>
                        <li className='img-hanrbot'>
                            <div class="img-handler-bot">
                                <img src={mapsd} />
                            </div>
                            <div class="ps-top">
                                <div class="whyus_text fortokens tok6" style={{ backgroundColor: 'tranparent' }}>
                                    <ul className='bilsimg'>
                                        <li style={{ height: 'auto' }}>Development Of Staking Module</li>                                    
                                        <li style={{ height: 'auto' }}>Liquidity Protocol Launch</li>
                                        <li style={{ height: 'auto' }}>Launching Of Staking Module</li>
                                        <li style={{ height: 'auto' }}>Development & Launch Of NFT Platform</li>
                                        <li style={{ height: 'auto' }}>Launch Of NFT Marketplace NFTials In  Dubai</li>

                                    </ul></div>
                            </div>
                            <span class="ps-sp-bot">Q4-2022</span>
                        </li>
                    </ol>
                </div>
            </section> */}
        </>
    )
}

export default Newroadmap
