import React from 'react'
import "../assets/Sectioneight.css";
import { useState, useCallback } from "react";



const Sectioneight = () => {
  const [actives, setActive] = useState(null);

  const [dataTabs, setDataTabs] = useState([
    {
      id: 1,
      tabTitle: "1. What is Crypto Market making?",
      tabClass: "Crypto market-making encompasses maintaining liquidity on a particular cryptocurrency by entering a cryptocurrency exchange's bid and ask limit orders. Market makers benefit by accumulating the bid-ask spread across numerous trades",
      tabClicked: false
    },
    {
      id: 2,
      tabTitle: "2. Who are the Market Makers?",
      tabClass: "Market makers are the financial markets' invisible hand. Market makers supply liquidity and profit from the market spread. The gap is the difference between the Market's bid and ask (or bid-offer) prices, or simply the difference between the buy and sell prices, as when exchanging money in a currency exchange kiosk.",
      tabClicked: false
    },
    {
      id: 3,
      tabTitle: "3. How do you manage High Liquidity Events?",
      tabClass: "This is where our experience comes into play. We have been developing and regularly training our algorithms to respond swiftly to price spikes with extreme volatility. We always configure our system to optimize market health (free float, spread, volatility, etc.) while maintaining inventory neutrality. This means that the setup of our system is only focused on not losing money.",
      tabClicked: false
    },
    {
      id: 4,
      tabTitle: "4. What supply is required to conduct Market Making?",
      tabClass: "The supply has no direct impact on the requirements of Market Making algorithms. The number of active players, average order size, and the number of target exchanges we will provide liquidity will determine the amount of inventory. The more of the above, the more inventory will be required.",
      tabClicked: false
    },
    {
      id: 5,
      tabTitle: "5. Why does one need a Market Maker?",
      tabClass: "Market makers provide platforms with more liquidity and comprehensive order books in enhancing trading execution and making platforms more appealing to customers",
      tabClicked: false
    }
  ]);


  
  const NavLink = ({ id, tabTitle,tabClass, isActive, onClick }) => {
    return (
      <button
        onClick={() => navigate(id)}
        className={isActive ? "actives" : ""}
      >
  
           
  <div class="row align-items-center">

        <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
    
    <div class="layouthomedx layoutpagr">
        <h2 style={{textAlign: 'left'}}>
        {tabTitle}
        </h2>
        </div>
        <p>{tabClass}</p>
        </div>
        </div>
      </button>
    ); 
  };

  const navigate = (id) => {
    setActive(id);
  };

  return (
    <>
       <>

      {dataTabs.map((item) => (
        <li className='adactive' key={item.id}>
          <NavLink {...item} isActive={actives === item.id} onClick={navigate} />
        </li>
      ))}
     
      </>
    </>
  );
};

export default Sectioneight
