import React from 'react'

const Appads = () => {
  return (
    <section class="section gradient-banner">
                
    <div class="containerxd-xl">
        <div class="row align-items-center">
            <div class="col-xs-12 col-sm-12 col-md-7 col-lg-6 order-2 order-md-1 text-center text-md-left">
                <h1 class="text-blck font-weight-bold mb-4">
                google.com, pub-8556203932022579, DIRECT, f08c47fec0942fa0
                </h1>
                </div>
                </div>
                </div>

 
    </section>
  )
}

export default Appads
