import React, {useRef} from 'react'
//import { Carousel } from '3d-react-carousal';
import './NewandMedia.css'
//import MyComponent from '3d-react-carousel'
//import '3d-react-carousel/dist/index.css'
import Carousel from 'react-elastic-carousel'

const NewandMedias = () => {


   const items = [
      {
       url: require('../assets/images/sized6.png'),
       href: '/'

      },
      {
       url: require('../assets/images/sized7.png'),
       href: '/'

      },     
      {
       url:  require('../assets/images/sized9.png'),
       href: 'https://tradeox.in/'
 
      }
    ]
    const carouselRef = useRef(null);
    let resetTimeout;
    
  return (
    <>
      
<Carousel
className='Carousel45'
 ref={carouselRef}
 pagination={false}
 enableAutoPlay={true}
 showArrows={true}
 autoPlaySpeed={4000}
 itemsToShow={2}
 isRTL={false}
 onNextEnd={({ index }) => {
     clearTimeout(resetTimeout);
     resetTimeout = setTimeout(() => {
         carouselRef?.current?.goTo(0);
     }, 4000); // same time
 }}>
{items.map(item =>
<div key={item.id}>
   <div className='bidBoxify'>
   <div className='bidBoxify25 bd256'>
   <a href={item.href} target='_blank'>
         <img src={item.url} alt="items" />
      <h5>{item.id}</h5>
 <p>{item.title}</p>
 </a>
   </div>
   </div>
</div>
   )}
</Carousel>
    </>
  )
}

export default NewandMedias





