import React from 'react'
import { useNavigate } from 'react-router';
import error from "../../assets/images/error.jpg"
import "./page.css"

function ErrorPage() {
    let navigate = useNavigate();
    function handleClick() {
        navigate('/')
    }
    return (
        <section>
            <div className='container'>
                <div className='row'>
                    <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                        <div>
                            <div className='mt-5 mb-5'>
                                <img src={error} alt="" className="img-fluid" />
                            </div>
                            <div className='d-flex justify-content-center mt-5 mb-5'>
                                <button type="button" className="btn btn-danger"
                                onClick={handleClick}>Go to Home </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ErrorPage
