import React from 'react'

const Amlpolicy = () => {

    return (
        <>
            <section class="section gradient-banner">

                <div class="containerxd-xl">
                    <div class="row align-items-center">
                        <div class="col-md-8 offset-md-2" id="pHdfght">
                           
                           
                           
                            <h3 style={{fontWeight: "bold",marginBottom: "10px", marginTop: "10px", textAlign: "center"}}>
                            Anti-Money Laundering (AML) Policy for FUFI (OneNine Labs FZ-LLC)
                            </h3>
                            


                            <b>Introduction</b><br/>
OneNine Labs FZ-LLC, the entity behind the FUFI blockchain ecosystem, is committed to preventing the misuse of its services for money laundering and terrorist financing activities. Operating from Ras Al Khaimah, UAE, we adhere to the highest standards of regulatory compliance, aligning our operations with both local 
and international AML regulations.<br/><br/>
<b>Policy Statement</b><br/><br/>
OneNine Labs FZ-LLC implements a rigorous AML policy to detect, prevent, and report any suspicious activities that may involve money laundering, terrorist financing, or other illegal financial transactions through the FUFI ecosystem. Our policy is designed to ensure the integrity and security of our operations and protect our clients and the financial system from abuse.<br/><br/>
<b>AML Compliance Program</b><br/><br/>
<ul>
<li><b>Customer Due Diligence (CDD):</b> We conduct thorough customer due diligence to identify and verify the identity of our customers. Enhanced due diligence is performed on customers presenting a higher risk, such as those involved in high-value transactions or from jurisdictions with high risks of financial crimes.</li>
<li><b>Transaction Monitoring:</b> Continuous monitoring of customer transactions to identify and report suspicious activities. Our system flags unusual patterns that may indicate money laundering or terrorist financing.</li>
<li><b>Employee Training:</b> Regular training programs for our employees to recognize and handle suspicious activities or transactions. Employees are educated on the latest AML regulations and compliance procedures.</li>
<li><b>Record Keeping:</b> Maintaining detailed records of customer identification, transactions, and reports filed for a minimum period as mandated by UAE law. This ensures that information is readily available for regulatory inspections or audits.</li>
<li><b>Compliance Officer:</b> Appointment of a dedicated AML Compliance Officer responsible for implementing and overseeing the AML policy. The Compliance Officer serves as the primary contact for regulatory bodies and law enforcement agencies.</li>
<li><b>Reporting Obligations:</b> Compliance with the legal requirement to report suspicious transactions to the UAE Financial Intelligence Unit (FIU) without delay. We cooperate fully with authorities in their efforts to combat financial crimes.</li>
<li><b>Risk Assessment:</b> Regular risk assessments of our operations and customer base to identify potential vulnerabilities to money laundering and terrorist financing. The assessments help in tailoring our AML strategies effectively.</li>
</ul>

<b>Commitment to Compliance</b><br/>
OneNine Labs FZ-LLC is fully committed to adhering to the AML statutory requirements set forth by the UAE and international regulatory bodies. We are dedicated to updating our policies and procedures in response to the evolving nature of financial crimes and regulatory landscapes.<br/><br/>
<b>Review and Update of AML Policy</b><br/>
This AML policy is reviewed annually to ensure its effectiveness and compliance with current laws and regulations. Any amendments or updates to the policy are implemented promptly to maintain the highest standards of AML compliance.<br/><br/>
<b>Declaration</b><br/>
By engaging with the FUFI ecosystem, our customers, partners, and stakeholders affirm their adherence to this AML policy and commit to uphold the principles of legal and ethical financial transactions within the platform.<br/><br/>
















<b>Privacy policy</b><br/><br/>

<b>Privacy Policy for FUFI (OneNine Labs FZ-LLC)</b><br/>
<b>Introduction</b><br/>
OneNine Labs FZ-LLC, the governing entity of the FUFI blockchain ecosystem, headquartered in Ras Al Khaimah, UAE, is dedicated to protecting the privacy and security of our users' information. This Privacy Policy outlines the types of information we collect, how it is used, and the measures we take to protect it.<br/><br/>
<b>Information Collection</b><br/>
<ul>
<li>
<b>Personal Identification Information: </b>We may collect personal information such as name, email address, physical address, telephone number, and identification numbers when users register on the FUFI platform, participate in transactions, or engage in other activities where such information is necessary.
Transactional Data: Transactional information on the FUFI blockchain, which may include public addresses and transaction amounts, is collected and stored as part of our operations. While this data is inherently public due to the nature of blockchain technology, we ensure the confidentiality of any off-chain transactions or services.
Usage and Technical Data: Information about how the services are accessed and used ("Usage Data") may include details such as your computer's Internet Protocol address (e.g., IP address), browser type, browser version, our service pages that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers, and other diagnostic data.

</li>
</ul>

<br/>
<b>Use of Information</b><br/>
To Provide and Maintain our Service: Including to monitor the usage of our service.
To Manage User Accounts: The personal information you provide can give you access to different functionalities of the service that are available to you as a registered user.
For Compliance and Enforcement: We use collected information for compliance with our legal obligations, resolution of disputes, and enforcement of our agreements and policies.
For Communication: We may use your information to contact you with newsletters, marketing or promotional materials, and other information that may be of interest to you.
<br/>
<b>Sharing and Disclosure</b><br/>
Legal Requirements: OneNine Labs FZ-LLC may disclose your Personal Data in the good faith belief that such action is necessary to comply with a legal obligation, protect and defend the rights or property of OneNine Labs FZ-LLC, prevent or investigate possible wrongdoing in connection with the service, protect the personal safety of users of the service or the public, and protect against legal liability.
Service Providers: We may employ third-party companies and individuals to facilitate our service ("Service Providers"), provide the service on our behalf, perform service-related services, or assist us in analyzing how our service is used. These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.
<br/>
<b>Data Security</b><br/>
We employ a variety of security measures designed to protect your information from unauthorized access, alteration, disclosure, or destruction. These include internal reviews of our data collection, storage and processing practices, and security measures, including appropriate encryption and physical security measures to guard against unauthorized access to systems where we store personal data.
<br/>
<b>Changes to This Privacy Policy</b><br/>
We reserve the right to update or change our Privacy Policy at any time. We will inform you of any significant changes by posting the new Privacy Policy on our platform and updating the "effective date" at the top of this document. You are advised to review this Privacy Policy periodically for any changes.





































                            </div>
                            </div></div>
                            </section>
                </>
                )
}

export default Amlpolicy
