import React from 'react'
import cryptoxin from '../assets/images/cryptoxin.gif'


const CryptoxIN = () => {
  return (
    <>
      <section class="section gradient-banner" style={{background:"#fff",padding:"50px 0 170px"}}>

        <div class="containerxd-xl">
          <div class="row align-items-center">
            <div class="col-xs-6 col-sm-6 col-md-7 col-lg-6 order-2 order-md-1 text-center text-md-left">
              
            <div className='rounded shadow p-52 bg-white' style={{backgroundColor:"#f3f3f3"}}>
              <h1 class="text-blck font-weight-bold mb-4">
              CryptoxIN
              </h1>
              <p>
              CryptoxIN, A Crypto Social Community Driven App Is An Exclusive Social Community Powered By Blockchain Technology. It Is Equipped With Audio/Video Calls And HubIN Broadcasting Capabilities Under One Single Platform. Furthermore, It Has Been Designed In A Manner That Serves The Next-Generation Digital/Virtual Use-Cases Including The Metaverse. It Is Arguably The Best Blockchain-Based Social Media Network.
              </p>
              <p class="text-blck mb-5">
<ul>
  <li>A Potential Alternative Of Zoom Calls And Google Meet Calls.</li>
  <li>It Hosts Multiple Tasks On A Daily Basis Which The Users Can Complete And Earn Rewards.</li>
  <li>It Offers An Engaging Community Of Professionals Interested In The Crypto And Stocks Market.</li>
  <li>CryptoxIN Is Powered By The FuFi Ecosystem And Is Responsible For Social Interactions And Engagements For The FuFi Community.</li>          
<li>CryptoxIN Is Live And Available For Use For 1 Year. It Has Already Been Tested For User-Friendliness 8 Months Ago.</li>
</ul>

              </p>
        <div className='buttontrd'>
        <a href="https://play.google.com/store/apps/details?id=com.cryptoxin" target="_blank">CryptoxIN</a>
                </div> 
            </div>
            </div>



            <div class="col-xs-6 col-sm-6 col-md-5 col-lg-6 text-center order-1 order-md-2">
              <img class="img-fluid" src={cryptoxin} alt="screenshot" />
            </div>
          </div>
        </div>
      </section>




    </>
  )
}

export default CryptoxIN
