import "../../assets/Sectioneight.css";

const Side = () => {
    return (
        <section>
            <div className="container">
                <div className="row">
                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sx-12 col-xs-12">
                        <div className="shadow rounded bg-white p-52 mt-5 mb-5">
                            <h2 className="headnc mt-3 mb-3">What is Sole Interest Deployer (SID)?</h2>
                            <p className="kjz">A Sole Interest Deployer (SID)  is an individual who burns his/her FUFI Coins.
                                The term “burning” in cryptocurrency refers to the act of eliminating a number
                                of Coins/Tokens from circulation. Burning tokens essentially removes them from
                                the available supply, reducing their number in circulation.</p>
                            <h2 className="headnc mt-5 mb-3">What are the benefits of SID?</h2>
                            <ul className="kjz">
                                <li>Becoming a SID member is very essential in FutureFi’s ecosystem because only SID members
                                    have access to all our highly beneficial products.</li>
                                <li>You can earn FUFI coins by becoming a Sole Interest Deployer (SID) in Future Finance Ecosystem,
                                    and you will earn a 70% block reward for a lifetime.</li>
                                <li>Earn 10% referral coins from every referral.</li>
                                <li>By Joining the Affiliate program you can earn from your 10 Level.</li>
                                <li>EBPs (Edge Block Producer), after 50% supply of FUFI is burned 6 high burners
                                    will get elected asminers in the FutureFiecosystem, and only SID members will get voting rights. </li>
                            </ul>
                            <h2 className="headnc mt-5 mb-3">Easy steps to become our SID member. </h2>
                            <p className="kjz">
                                Here are the steps to become a SID member of the FutureFi ecosystem, as described in
                                the paragraph:
                            </p>
                            <ol className="kjz">
                                <li>Purchase FUFI coins from an Exchange.</li>
                                <li>Download the <a href="https://play.google.com/store/apps/details?id=com.futurefinance.fufiedge&hl=en_IN&gl=US"
                                className="link-fink"
                                >Fufiedge wallet</a>.</li>
                                <li>Create your WEB3.0 wallet or import an existing web3 wallet.</li>
                                <li>Send your FUFI coins from the exchange to your Fufiedge wallet.</li>
                                <li>Click on the SID process in the Fufiedge wallet.</li>
                                <li>Click on "be a SID".</li>
                                <li>Enter any referral code you have and the amount of FUFI coins you want to burn.</li>
                            </ol>
                            <p className="kjz">
                                Finally, confirm the transaction and you will become a SID member of the FutureFi
                                ecosystem.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default Side;