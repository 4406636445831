import React, { useState, useEffect } from 'react'
import { Link, useNavigate, NavLink } from "react-router-dom";
import download21 from '../assets/images/download21.png'
import privacypolicy from '../assets/images/FuFi PRIVACY POLICY.pdf'
import boxed12 from '../assets/images/sized6.png';
import boxed13 from '../assets/images/sized7.png';
import boxed25 from '../assets/images/sized9.png';
import discord from '../assets/images/Discord.png';
import telegram from '../assets/images/telegram.png';


const Footer = () => {

    const [date, setDate] = useState();

    const getYear = () => setDate(new Date().getFullYear())


    useEffect(() => {
        getYear();
    }, [])

    const scrollTos = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    return (
        <>

            <footer class="kilimanjaro_area">

                <div class="foo_top_header_one section_padding_100_70">
                    <div class="containerxd-xl">
                        <div class="row">
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="kilimanjaro_part">
                                    <div className='kilimanjaro_part25'>
                                        <img src={download21} />
                                    </div>
                                    <p className='mr-3'>
                                        Future Finance is a high-end blockchain platform that incorporates artificial intelligence and other cutting-edge technologies to optimize a wide range of financial and non-financial use cases.
                                    </p>

                                </div>
                                <div class="kilimanjaro_part m-top-15 ">
                                    <h5>Listing</h5>
                                    <ul class="kilimanjaro_social_links">
                                        <li><a href="/" target="_blank">
                                            <img src={boxed12} />
                                        </a></li>
                                        <li><a href="/" target="_blank">
                                            <img src={boxed13} />
                                        </a></li>
                                        <li><a href="https://tradeox.in/" target="_blank">
                                            <img src={boxed25} />
                                        </a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="kilimanjaro_part m-top-15 ">
                                    <h5>The FutureFi Ecosystem</h5>
                                    <ul class="kilimanjaro_links">
                                        <li><a href="https://fufi.info/TradeKia" target="_blank">TradeKia</a></li>
                                        <li><a href="https://fufi.info/TradeBRO" target="_blank">TradeBRO</a></li>
                                        <li><a href="https://fufi.info/FufiedgeWallet" target="_blank">FUFIEDGE</a></li>
                                        <li><a href="https://fufi.info/Trade0x" target="_blank">Trade0X</a></li>
                                      
                                        <li><a href="https://fufi.info/metaverse" target="_blank">Metaverse &amp; NFTials</a></li>
                                        <li><NavLink to="varifie" target="_blank">Varifie</NavLink></li>
                                    </ul>
                                </div>


                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="kilimanjaro_part m-top-15 mr-3">
                                    <h5>Updates</h5>
                                    <ul class="kilimanjaro_links">
                                        <li><a href="https://content.fufi.info/announcements/" target="_blank">Announcement</a></li>
                                        <li><a href="https://content.fufi.info/announcements/press-release/" target="_blank">Press Release</a></li>
                                        {/* <li><a href="https://fufi.info/team" target="_blank">Team</a></li> */}
                                        <li>
                                        <NavLink className="navLnksda" to="/PrivacyPolicy" onClick={() => scrollTos('#pHdfght')}>
                                        Privacy Policy
                                            </NavLink>
                                       
                                        </li>

                                        {/*<li>
                                        <a href="/NonReundable" target="_blank">NON-Refundable Policy</a>
    </li>*/}
                                        <li>
                                            <NavLink className="navLnksda" to="/amlpolicy" onClick={() => scrollTos('#pHdfght')}>
                                                AML policy
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="kilimanjaro_part m-top-15 ">
                                    <h5>Glossary</h5>
                                    <ul class="kilimanjaro_links">
                                        <li><a href="https://coinmarketcap.com/currencies/futurefi/" target="_blank">CoinMarketCap</a></li>
                                        <li><a href="https://www.coingecko.com/en/coins/futurefi" target="_blank">Coingecko</a></li>
                                        <li><a href="https://www.binance.com/en/price/futurefi" target="_blank">Binance</a></li>
                                        <li><a href="https://www.coinbase.com/price/futurefi" target="_blank">Coinbase</a></li>
                                        <li><a href="https://crypto.com/price/futurefi" target="_blank">Crypto.com</a></li>
                                        <li><a href="https://tradekia.com/" target="_blank">TradeKia</a></li>
                                        {/* <li><a href="https://international.indoex.io/trade/FUFI_USDT" target="_blank">Indoex</a></li> */}

                                    </ul>
                                </div>
                            </div>
                        </div>











                        <div class="row">
                            <div class="col-12 col-md-12 col-lg-12">
                                <div className="social-menu">
                                    <ul>
                                        <li>
                                            <a href="https://discord.gg/UAcKuUPSh2" target="_blank">

                                                <img src={discord} style={{ width: '2.1rem' }} />
                                            </a>
                                            <div class="box">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </div>
                                        </li>



                                        <li>
                                            <a href="https://www.instagram.com/futuresfinance_fufi/" target="_blank">
                                                <i class="fa fa-instagram" aria-hidden="true"></i>
                                            </a>
                                            <div class="box">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </div>
                                        </li>



                                        <li>
                                            <a href="https://www.facebook.com/futuresfinance.fufi" target="_blank"><i class="fa fa-facebook-square" aria-hidden="true"></i></a>
                                            <div class="box">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </div>
                                        </li>




                                        <li>
                                            <a href="https://twitter.com/futuresfinance" target="_blank"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                                            <div class="box">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </div>
                                        </li>
                                        <li>
                                            <a href="https://www.youtube.com/channel/UC8t6N4uzi_CjA54oKCyWG0Q" target="_blank"><i class="fa fa-youtube" aria-hidden="true"></i></a>
                                            <div class="box">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </div>
                                        </li>
                                        <li>
                                            <a href="https://medium.com/@futuresfinance.fufi" target="_blank"><i class="fa fa-medium" aria-hidden="true"></i></a>
                                            <div class="box">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </div>
                                        </li>

                                        <li>
                                            <a href="https://www.linkedin.com/company/futuresfinance-fufi" target="_blank"><i class="fa fa-linkedin" aria-hidden="true"></i></a>
                                            <div class="box">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </div>
                                        </li>

                                        <li>
                                            <a href="https://t.me/futuresfinance" target="_blank">
                                                <img src={telegram} alt="Fufi" />
                                            </a>
                                            <div class="box">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </div>
                                        </li>


                                    </ul>
                                </div>


                            </div>
                        </div>



                    </div>
                </div>

                <div class=" kilimanjaro_bottom_header_one section_padding_50 text-center">
                    <div class="containerxd-xl">
                        <div class="row">
                            <div class="col-12">
                                <p>Copyright © {date}. All rights reserved by Future's Finance</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer
