import React from 'react';
import { Routes, Route } from "react-router-dom";

import Homepage from '../src/container/Homepage'
import './App.css';
import '../src/assets/plugins/bootstrap/bootstrap.min.css'
import '../src/assets/plugins/slick/slick.css'
import '../src/assets/plugins/slick/slick-theme.css'
import '../src/assets/plugins/fancybox/jquery.fancybox.min.css'
import '../src/assets/plugins/aos/aos.css'
import '../src/assets/plugins/style.css'
import Aboutus from "../src/container/Aboutus"
import Navigationbar from '../src/layout/Navigationbar'
//import Navigation from '../src/layout/Navigation/Navigationbarnew'
import Footer from '../src/layout/Footer'
import TradeKia from '../src/container/TradeKia'
import FufiedgeWallet from '../src/container/FufiedgeWallet'
import Trade0x from '../src/container/Trade0x'
import CryptoxIN from '../src/container/CryptoxIN'
import TradeBRO from '../src/container/TradeBRO'
import Metaverse from '../src/container/Metaverse'
import Timeline from '../src/container/timeline/Timeline'
import Amlpolicy from '../src/container/policies/Amlpolicy'
import PrivacyPolicy from '../src/container/policies/PrivacyPolicy'
import NonReundable from './container/policies/NonReundable'
import Side from './container/becomeSide/Side';
import Verification from '../src/container/Verification'
import Appads from '../src/container/Appads'
import { HelmetProvider } from 'react-helmet-async';
import BeAPartOfEcosytem from "./container/BeAPart/BeAPartOfEcosytem"
import Errorpage from './container/ErrorPage/ErrorPage';

const helmetContext = {};

function App() {

  return (
    <> 
      <HelmetProvider context={helmetContext}>
        <Navigationbar />
        <Routes>
          <Route path={"/"} element={<Homepage />} />
          <Route path="/aboutus" element={<Aboutus />}></Route>
          <Route path="/TradeKia" element={<TradeKia />}></Route>
          <Route path="/FufiedgeWallet" element={<FufiedgeWallet />}></Route>
          <Route path="/Trade0x" element={<Trade0x />}></Route>
          <Route path="/NonReundable" element={<NonReundable />}></Route>
          <Route path="/CryptoxIN" element={<CryptoxIN />}></Route>
          <Route path="/TradeBRO" element={<TradeBRO />}></Route>
          <Route path="/Metaverse" element={<Metaverse />}></Route>
          <Route path="/Timeline" element={<Timeline />}></Route>
          <Route path="/varifie" element={<Verification />}></Route>
          <Route path="/amlpolicy" element={<Amlpolicy />}></Route>
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />}></Route>
          <Route path="/app-ads" element={<Appads />}></Route>
          <Route path="/be-aPart-of-ecosytem" element={<BeAPartOfEcosytem />}></Route>
          <Route path="/SID" element={<Side />}></Route>
          <Route path="*" element={<Errorpage />}></Route>
          {/* <Route path="/Timelineanim" element={<Timelineanim/>}></Route> */}
        </Routes>
        <Footer />

      </HelmetProvider>
    </>
  );
}

export default App;
