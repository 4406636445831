import React from 'react'


import Durga from '../assets/images/Durga.png';
//import praveen from '../assets/images/praveen.png';
import Saurabh from '../assets/images/Saurabh.png';
//import Deepu from '../assets/images/Deepu.png';
import shoaib from '../assets/images/shoaib.png';
import Hirendra from '../assets/images/Hirendra.png';
import arpita from '../assets/images/arpita.png';
import about from '../assets/images/aboutus.png';
import img6 from '../assets/images/img15.png';
import shashikant from '../assets/images/shashikant sir.png';
import Shalu from '../assets/images/shalu maam.png';
//import prakhar from '../assets/images/prakhar.png';
//import sheetal from '../assets/images/sheetal.png';
//import satyendra from '../assets/images/satyendra sir.png';
//import SaurabhV from '../assets/images/Sourbh.png'
//import Kritika from '../assets/images/Kritika.png'
//import suraj from '../assets/images/SURAJ.png'
//import ayushi from '../assets/images/AyushiShukla.png'
//import akanksha from '../assets/images/akanksha.png'
import ajeet from '../assets/images/ajeet.png'
//import swati from '../assets/images/swati.png'
import '../assets/aboutus.css'
import { Helmet } from "react-helmet";

const Aboutus = () => {
  return (
    <>

      <Helmet>
        <title>Future Finance</title>
        <meta name="description" content="Future Finance is a high-end blockchain platform that incorporates artificial intelligence and other cutting-edge technologies to optimize how people handle their digital assets. It uses Proof of Authority, Proof of Burnt Stake, and Delegated Proof of Stake to offer a fair, transparent, and scalable platform that can host a range of decentralized applications for real-life use cases." />
        <meta name="keywords" content="Future Finance About US"></meta>
      </Helmet>

      <section class="section gradient-banner">
        <div className="containerxd-xl" style={{ marginTop: "3%" }}>

          <div class="row align-items-center">
            <div class="col-xs-6 col-sm-6 col-md-7 col-lg-6 order-2 order-md-1 text-center text-md-left">
              <h1 class="text-blck font-weight-bold mb-4">
                About Us
              </h1>
              <p class="text-blck mb-5">
                Future Finance is a high-end blockchain platform that incorporates artificial intelligence and other cutting-edge technologies to optimize how people handle their digital assets. It uses Proof of Authority, Proof of Burnt Stake, and Delegated Proof of Stake to offer a fair, transparent, and scalable platform that can host a range of decentralized applications for real-life use cases.


                <br />
                FutureFi is an umbrella brand that hosts several products and services, each of which is dedicated to optimizing the future of financial operations across the globe. All in all, FutureFi harnesses the features of web 3.0, blockchain technology, artificial intelligence and machine learning while delivering advanced solutions to complex problems.

              </p>

            </div>
            <div class="col-xs-6 col-sm-6 col-md-5 col-lg-6 text-center order-1 order-md-2">
              <img class="img-fluid" src={about} />
            </div>
          </div>





        </div>





        <div className="containerxd-xl">

          <div className='row'>


            <div className='col-12 col-sm-12 col-md-6'>
              <div className='rowbox'>
                <div className='row'>
                  <div className='col-12 col-sm-5 col-md-5'>
                    <div className='ourtmsb'>
                      <img className="fufi-emp" src={Durga} alt="Fufi" />
                    </div>

                  </div>
                  <div className='col-12 col-sm-7 col-md-7'>
                    <div class="detbox">
                      <p class="name colrs boxnts">Durga P Tripathi</p>
                      <p class="designation dark">Founder & CEO</p>
                    </div>
                    <div className="mainbx">
                      <ul id="buttons">

                        <li class="tw">
                          <a href="https://twitter.com/d_p_tripathi" title="Twitter" target="_blank">
                            <i class="fa fa-twitter" style={{ top: '15px' }} aria-hidden="true"></i>
                          </a>
                        </li>

                        <li class="fb">
                          <a href="https://www.linkedin.com/in/durga-prasad-tripathi-210a60107/" target="_blank" title="Github">
                            <i class="fa fa-linkedin" style={{ top: '15px' }} aria-hidden="true"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            


          </div>



          <div class="at-grid" data-column="4">

            <div class="at-column">
              <div class="at-user">
                <div class="at-user__avatar">
                  <img className="fufi-emp" src={shoaib} alt="Fufi" />
                </div>

                <div class="detbox">
                  <p class="namesd colrd">Shoaib Malik</p>
                  <p class="designation dark colrsbk mints">CHO</p>
                </div>
                <div className="mainbx btons" style={{ marginTop: '2rem', float: 'left' }}>
                  <ul id="buttons">

                    <li class="tw" style={{ background: 'transparent', color: '#ccc', width: ' 40px', height: ' 50px' }}>
                      <a href="https://twitter.com/Shubby_salman" title="Twitter" target="_blank">
                        <i class="fa fa-twitter" aria-hidden="true" style={{ color: '#ccc' }}></i>
                      </a>
                    </li>

                    <li class="fb" style={{ background: 'transparent', color: '#ccc', width: ' 40px', height: ' 50px' }}>
                      <a href="https://www.linkedin.com/in/shoaib-malik/" title="Github" target="_blank">
                        <i class="fa fa-linkedin" aria-hidden="true" style={{ color: '#ccc' }}></i>
                      </a>
                    </li>
                  </ul>
                </div>

              </div>
            </div>



            <div class="at-column">
              <div class="at-user">
                <div class="at-user__avatar">
                  <img className="fufi-emp" src={Saurabh} alt="Fufi" />
                </div>


                <div class="detbox">
                  <p class="namesd colrd">Saurabh Mishra</p>
                  <p class="designation dark colrsbk mints">CTO (Tech Lead)</p>
                </div>
                <div className="mainbx btons" style={{ marginTop: '2rem', float: 'left' }}>
                  <ul id="buttons">

                    <li class="tw" style={{ background: 'transparent', color: '#ccc', width: ' 40px', height: ' 50px' }}>
                      <span>
                        <i class="fa fa-twitter" aria-hidden="true" style={{ color: '#ccc' }}></i>
                      </span>
                    </li>

                    <li class="fb" style={{ background: 'transparent', color: '#ccc', width: ' 40px', height: ' 50px' }}>
                      <a href="https://www.linkedin.com/in/sourabh-mishra-171753116/" title="Github" target="_blank">
                        <i class="fa fa-linkedin" aria-hidden="true" style={{ color: '#ccc' }}></i>
                      </a>
                    </li>
                  </ul>
                </div>

              </div>
            </div>
            <div class="at-column">
              <div class="at-user">
                <div class="at-user__avatar">
                  <img className="fufi-emp" src={arpita} alt="Fufi" />
                </div>


                <div class="detbox">
                  <p class="namesd colrd">Arpita Shukla</p>
                  <p class="designation dark colrsbk mints">Manager operation</p>
                </div>
                <div className="mainbx btons" style={{ marginTop: '2rem', float: 'left' }}>
                  <ul id="buttons">

                    <li class="tw" style={{ background: 'transparent', color: '#ccc', width: ' 40px', height: ' 50px' }}>
                      <a href="https://twitter.com/arpitashukla244?t=2LFY_kg35D7aCkqJJi58bQ&s=09" title="Twitter" target="_blank">
                        <i class="fa fa-twitter" aria-hidden="true" style={{ color: '#ccc' }}></i>
                      </a>
                    </li>

                    <li class="fb" style={{ background: 'transparent', color: '#ccc', width: ' 40px', height: ' 50px' }}>
                      <a href="https://www.linkedin.com/in/arpita-shukla-4b419021a" title="Github" target="_blank">
                        <i class="fa fa-linkedin" aria-hidden="true" style={{ color: '#ccc' }}></i>
                      </a>
                    </li>
                  </ul>
                </div>

              </div>
            </div>

            <div class="at-column">
              <div class="at-user">
                <div class="at-user__avatar">
                  <img className="fufi-emp" src={shashikant} alt="Fufi" />
                </div>


                <div class="detbox">
                  <p class="namesd colrd">Shashikant Shukla</p>
                  <p class="designation dark colrsbk mints">VP,Marketing</p>
                </div>
                <div className="mainbx btons" style={{ marginTop: '2rem', float: 'left' }}>
                  <ul id="buttons">

                    <li class="tw" style={{ background: 'transparent', color: '#ccc', width: ' 40px', height: ' 50px' }}>
                      <a href="https://twitter.com/shashikantshu13" title="Twitter" target="_blank">
                        <i class="fa fa-twitter" aria-hidden="true" style={{ color: '#ccc' }}></i>
                      </a>
                    </li>

                    <li class="fb" style={{ background: 'transparent', color: '#ccc', width: ' 40px', height: ' 50px' }}>
                      <a href="https://www.linkedin.com/in/shashikant-shukla-19026391/" title="Github" target="_blank">
                        <i class="fa fa-linkedin" aria-hidden="true" style={{ color: '#ccc' }}></i>
                      </a>
                    </li>
                  </ul>
                </div>

              </div>
            </div>




            <div class="at-column">
              <div class="at-user">
                <div class="at-user__avatar">
                  <img className="fufi-emp" src={Shalu} alt="Fufi" />
                </div>


                <div class="detbox">
                  <p class="namesd colrd">Shalu Singh</p>
                  <p class="designation dark colrsbk mints">Human Resource Officer</p>
                </div>
                <div className="mainbx btons" style={{ marginTop: '2rem', float: 'left' }}>
                  <ul id="buttons">

                    <li class="tw" style={{ background: 'transparent', color: '#ccc', width: ' 40px', height: ' 50px' }}>
                      <a href="https://twitter.com/itshalusingh" title="Twitter" target="_blank">
                        <i class="fa fa-twitter" aria-hidden="true" style={{ color: '#ccc' }}></i>
                      </a>
                    </li>

                    <li class="fb" style={{ background: 'transparent', color: '#ccc', width: ' 40px', height: ' 50px' }}>
                      <a href="https://www.linkedin.com/in/shalu-singh-597456199/" title="Github" target="_blank">
                        <i class="fa fa-linkedin" aria-hidden="true" style={{ color: '#ccc' }}></i>
                      </a>
                    </li>
                  </ul>
                </div>

              </div>
            </div>

            <div class="at-column">
              <div class="at-user">
                <div class="at-user__avatar">
                  <img className="fufi-emp" src={img6} alt="Fufi" />
                </div>

                <div class="detbox">
                  <p class="namesd colrd">Shailja</p>
                  <p class="designation dark colrsbk mints">Front End Developer</p>
                </div>
                <div className="mainbx btons" style={{ marginTop: '2rem', float: 'left' }}>
                  <ul id="buttons">

                    <li class="tw" style={{ background: 'transparent', color: '#ccc', width: ' 40px', height: ' 50px' }}>
                      <a href="https://twitter.com/kyotojp3" title="Twitter" target="_blank">
                        <i class="fa fa-twitter" aria-hidden="true" style={{ color: '#ccc' }}></i>
                      </a>
                    </li>

                    <li class="fb" style={{ background: 'transparent', color: '#ccc', width: ' 40px', height: ' 50px' }}>
                      <a href="https://www.linkedin.com/in/shailja-frontend-ui-developer-at-future-s-finance-727730214/" title="linkedin" target="_blank">
                        <i class="fa fa-linkedin" aria-hidden="true" style={{ color: '#ccc' }}></i>
                      </a>
                    </li>
                  </ul>
                </div>

              </div>
            </div>

            <div class="at-column">
              <div class="at-user">
                <div class="at-user__avatar">
                  <img className="fufi-emp" src={Hirendra} alt="Fufi" />
                </div>


                <div class="detbox">
                  <p class="namesd colrd">Hirendra</p>
                  <p class="designation dark colrsbk mints">Blockchain Developer</p>
                </div>
                <div className="mainbx btons" style={{ marginTop: '2rem', float: 'left' }}>
                  <ul id="buttons">

                    <li class="tw" style={{ background: 'transparent', color: '#ccc', width: ' 40px', height: ' 50px' }}>
                      <a href="https://twitter.com/Hirendr258" title="Twitter" target="_blank">
                        <i class="fa fa-twitter" aria-hidden="true" style={{ color: '#ccc' }}></i>
                      </a>
                    </li>

                    <li class="fb" style={{ background: 'transparent', color: '#ccc', width: ' 40px', height: ' 50px' }}>
                      <a href="https://www.linkedin.com/in/hirendrasahu/" title="Github" target="_blank">
                        <i class="fa fa-linkedin" aria-hidden="true" style={{ color: '#ccc' }}></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>


            <div class="at-column">
              <div class="at-user">
                <div class="at-user__avatar">
                  <img className="fufi-emp" src={ajeet} alt="Fufi" />
                </div>


                <div class="detbox">
                  <p class="namesd colrd">Ajit Singh</p>
                  <p class="designation dark colrsbk mints">flutter Developer</p>
                </div>
                <div className="mainbx btons" style={{ marginTop: '2rem', float: 'left' }}>
                  <ul id="buttons">

                    <li class="tw" style={{ background: 'transparent', color: '#ccc', width: ' 40px', height: ' 50px' }}>
                      <a href="https://twitter.com/AjitSin34765069" title="Twitter" target="_blank">
                        <i class="fa fa-twitter" aria-hidden="true" style={{ color: '#ccc' }}></i>
                      </a>
                    </li>

                    <li class="fb" style={{ background: 'transparent', color: '#ccc', width: ' 40px', height: ' 50px' }}>
                      <a href="https://www.linkedin.com/in/ajit-singh-8a49a4229/" title="Github" target="_blank">
                        <i class="fa fa-linkedin" aria-hidden="true" style={{ color: '#ccc' }}></i>
                      </a>
                    </li>
                  </ul>
                </div>

              </div>
            </div>

          </div>
        </div>
      </section>

    </>
  )
}

export default Aboutus
