import React from 'react'

const PrivacyPolicy = () => {

    return (
        <>
            <section class="section gradient-banner">

                <div class="containerxd-xl">
                    <div class="row align-items-center">
                        <div class="col-md-8 offset-md-2" id="pHdfght">
                           
                           
                           
                            <h3 style={{fontWeight: "bold",marginBottom: "10px", marginTop: "10px", textAlign: "center"}}>
                            Privacy Policy for FUFI (OneNine Labs FZ-LLC)
                            </h3>

<b>Introduction</b><br/>
OneNine Labs FZ-LLC, the governing entity of the FUFI blockchain ecosystem, headquartered in Ras Al Khaimah, UAE, is dedicated to protecting the privacy and security of our users' information. This Privacy Policy outlines the types of information we collect, how it is used, and the measures we take to protect it.<br/><br/>
<b>Information Collection</b><br/>
<ul>
<li>
<b>Personal Identification Information: </b>We may collect personal information such as name, email address, physical address, telephone number, and identification numbers when users register on the FUFI platform, participate in transactions, or engage in other activities where such information is necessary.
Transactional Data: Transactional information on the FUFI blockchain, which may include public addresses and transaction amounts, is collected and stored as part of our operations. While this data is inherently public due to the nature of blockchain technology, we ensure the confidentiality of any off-chain transactions or services.
Usage and Technical Data: Information about how the services are accessed and used ("Usage Data") may include details such as your computer's Internet Protocol address (e.g., IP address), browser type, browser version, our service pages that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers, and other diagnostic data.

</li>
</ul>

<br/>
<b>Use of Information</b><br/>
To Provide and Maintain our Service: Including to monitor the usage of our service.
To Manage User Accounts: The personal information you provide can give you access to different functionalities of the service that are available to you as a registered user.
For Compliance and Enforcement: We use collected information for compliance with our legal obligations, resolution of disputes, and enforcement of our agreements and policies.
For Communication: We may use your information to contact you with newsletters, marketing or promotional materials, and other information that may be of interest to you.
<br/>
<b>Sharing and Disclosure</b><br/>
Legal Requirements: OneNine Labs FZ-LLC may disclose your Personal Data in the good faith belief that such action is necessary to comply with a legal obligation, protect and defend the rights or property of OneNine Labs FZ-LLC, prevent or investigate possible wrongdoing in connection with the service, protect the personal safety of users of the service or the public, and protect against legal liability.
Service Providers: We may employ third-party companies and individuals to facilitate our service ("Service Providers"), provide the service on our behalf, perform service-related services, or assist us in analyzing how our service is used. These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.
<br/>
<b>Data Security</b><br/>
We employ a variety of security measures designed to protect your information from unauthorized access, alteration, disclosure, or destruction. These include internal reviews of our data collection, storage and processing practices, and security measures, including appropriate encryption and physical security measures to guard against unauthorized access to systems where we store personal data.
<br/>
<b>Changes to This Privacy Policy</b><br/>
We reserve the right to update or change our Privacy Policy at any time. We will inform you of any significant changes by posting the new Privacy Policy on our platform and updating the "effective date" at the top of this document. You are advised to review this Privacy Policy periodically for any changes.





































                            </div>
                            </div></div>
                            </section>
                </>
                )
}

export default PrivacyPolicy
