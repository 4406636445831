import "./part.css"
import downloadFufi from "../../assets/images/downloadFufi.png"
import depositExchange from "../../assets/images/depositExchange.png"
import burnFufi from "../../assets/images/burnFufi.png"

const BeAPartOfEcosytem = () => {
    return (
        <>
            <section>
                <div className="container">
                    <div className="row">

                        <div>
                            <h2 className="font-text-one  
                        ">BE A PART OF OUR ECOSYSTEM IN 3 EASY STEPS</h2>
                        </div>

                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">

                            <div className="shadow p-3 mb-5 bg-body-tertiary rounded 
                               display-setter-one">
                                <div className="image-center-one">
                                    <img src={downloadFufi} alt="logo" className="img-fluid" />
                                </div>
                                <h2 className="font-text">DOWNLOAD FUFIEDGE APP</h2>
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div className="row">
                                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="shadow p-3 mb-5 bg-body-tertiary rounded display-setter">
                                        <div className="image-center 
                                        ">
                                            <img src={depositExchange} alt="logo" className="img-fluid" />
                                        </div>
                                        <h2 className="font-text">DEPOSIT FUFI FROM THE EXCHANGE</h2>
                                    </div>
                                </div>
                                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="shadow p-3 mb-5 bg-body-tertiary rounded display-setter">
                                        <div className="image-center 
                                         ">
                                            <img src={burnFufi} alt="logo" className="img-fluid" />
                                        </div>
                                        <h2 className="font-text">BURN FUFI COINS</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
};

export default BeAPartOfEcosytem;