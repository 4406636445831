import React from 'react'
import tradekia from '../assets/images/tradekia.gif'


const Trade0x = () => {
  return (
    <>
      <section class="section gradient-banner" style={{background:"#fff",padding:"50px 0 170px"}}>

        <div class="containerxd-xl">
          <div class="row align-items-center">
            <div class="col-xs-6 col-sm-6 col-md-7 col-lg-6 order-2 order-md-1 text-center text-md-left">
              
            <div className='rounded shadow p-52 bg-white' style={{backgroundColor:"#f3f3f3"}}>
              <h1 class="text-blck font-weight-bold mb-4">
              Trade0x
              </h1>
           
              <p class="text-blck mb-5">
              Trade0x Is A Decentralized Crypto Exchange Built To Empower The FuFi Network.
              <br />
              Trade0x Is Built To Provide Spot Trades Over FUFI Assets.
                <br />
                It Is A Hybrid Product Of TradeKIA And Works With Both The FUFI EDGE Wallet As Well As The TradeKIA Hot Wallet.
                <br/>
                A Derivative exchange where Futures of current financial products will be traded.            
                <br/>
                Trade0x Is Powered By A Web 3.0 Decentralized Exchange.
                <br/>
                It Is Core Competitive In Nature And Can Handle MultiChain Interactions.


              </p>
              {/* <div className='buttontrd'>
                  <a href="https://tradekia.com/" target="_blank">TradeKia</a>
                </div> */}
            </div>
            </div>



            <div class="col-xs-6 col-sm-6 col-md-5 col-lg-6 text-center order-1 order-md-2">
              <img class="img-fluid" src={tradekia} alt="screenshot" />
            </div>
          </div>
        </div>
      </section>




    </>
  )
}

export default Trade0x
