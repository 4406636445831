import React,{useRef} from 'react'
import tradebro from '../assets/images/tradebro.gif'
import emailjs from 'emailjs-com';
import contactradekiya from '../assets/images/contactradekiya.svg'

import Sectioneight from "../container/Sectioneight";




import playst1 from '../assets/images/playst1.svg'
import playst2 from '../assets/images/playst2.svg'
import CMMS from '../assets/images/CMMS.svg'
import cryptomarketmaking from '../assets/images/cryptomarketmaking.svg'
import abouttradebro from '../assets/images/abouttradebro.svg'
import tradebrx from '../assets/images/abouttradebro.svg'


const TradeBRO = () => {

  const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_z2fb3et', 'template_zd29tfw', form.current, 'f3yzYopHpShdpmMaV')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });

          alert("Welcome")
      };
  return (
    <>
    <section class="section gradient-banner" style={{background:"#fff",padding:"20px 0 80px"}}>
    <div className="containerxd-xl">

    
        
    <div class="row align-items-center">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
      
    
      <div className='rounded shadow p-52 bg-white' style={{backgroundColor:"#f3f3f3"}}>
      <h1 class="text-blck font-weight-bold mb-4">
        TRADEBRO
        </h1>
      
        <p class="text-blck mb-5">
      We were revolutionizing Crypto Market-Making for Crypto tokens and Exchanges. We
      lay the monetary groundwork for ambitious
      and ground-breaking token economies to
      flourish
      </p>
    
    
     </div> 
        </div>
          
          
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <div className='tradebroFGX'>
      <img src={tradebro}  class="img-fluid"   /> 
      </div>
          </div>
      
      
      </div>
      
      <div class="row align-items-center">
      
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 d-flex justify-content-between align-items-center">
        
     
  
          <div className='tradebroFGX'>
      <img src={CMMS} class="img-fluid" style={{position:'relative'}} /> 
      </div>
      </div>
      
      
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
      <div className='rounded shadow p-52 bg-white' style={{backgroundColor:"#f3f3f3"}}>
      <h2 class="text-blck font-weight-bold mb-4">
        Crypto Market<br/>
      Making Services
      
        </h2>
     
        <p class="text-blck mb-5">
      Market Maker provides higher liquidity and full order books
      to crypto tokens and exchanges to improve trading
      performance and make the platforms more appealing to
      traders. TradeBRO cryptocurrency market-making solution
      generates order books with persistent deep liquidity, tight
      spreads, deep trading volume, and predictable pricing.
      Increase the volume of ask-bid spreads and prices in your
      exchanges by utilizing the industry's most significant
      institutional-grade liquidity providers.
      </p>
      <div className='knowmore2'>
         <div className='butons btns1'>
           <a href="mailto:support@fufi.info" style={{color:'#000'}}>
           Get in Touch with us
           </a>
           </div> 
      </div>
      </div>
      
        </div>
      </div>




      <div class="row align-items-center">
    
      
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
    
      <div className='rounded shadow p-52 bg-white' style={{backgroundColor:"#f3f3f3"}}>
      <h2 class="text-blck font-weight-bold mb-4">
      Making Services What are the Benefits of<br/>
      Crypto Market-Making?
        </h2>
     
      <p class="text-blck mb-5">
      Markets with limited liquidity will typically have broad bid-ask
      spreads in their order books, which can exacerbate assets volatility.
      As a result, it is harder for traders to obtain a fair price for their
      trades and have their orders executed. The overall liquidity
      significantly impacts its growth, and market makers play an
      essential role in ensuring liquidity. Said, an asset's liquidity is the
      ease with which buyers and sellers can exchange it at any moment.
      <ul>
      <li>Boost market liquidity and the depth of order book.</li>
      <li>Price fluctuations are reduced.</li>
      <li>Assist in the discovery of fair prices</li>
      <li>Easy to manage bid-ask spreads in cryptocurrency exchange order
      books.</li>
      <li>Traders have more organized entry and exit points.</li>
      <li>Significantly reduces slipping.</li>
      <li>Assist in accommodating huge institutional investments.</li>
      </ul>
      </p>
  
      
      <div className='knowmore2' style={{justifyContent:'left'}}>
         <div className='butons btns1 btnhre' style={{lineHeight: "22px", paddingTop: "8px", paddingBottom: "9px"}}>
         <a href="mailto:support@fufi.info" style={{color:'#000'}}>
           Learn More About Market Making
           </a>
           </div> 
      </div>
      </div>
      </div>
    
      
      
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <div className='tradebroFGX'>
      <img src={cryptomarketmaking} class="img-fluid" style={{position:'relative'}} /> 
      </div>
          </div>
   
      
      
 
      </div> 
















      <div class="row align-items-center" style={{backgroundColor:"#FEB100",marginTop: "3rem",boxShadow: "0 .5rem 1rem rgba(0,0,0,.30)",borderRadius: "8px", paddingRight:"3rem"}}>
   
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5">
      <div className='tradebroFGX'>
     <img class="img-fluid" src={tradebrx} alt="screenshot" />
     </div>
     </div>

      
      <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7">
    
      
      <h2 class="text-blck font-weight-bold mb-4" style={{ paddingTop:"3rem"}}>
      About
      </h2>
     
       
     
      <p class="text-blck mb-5">
      TradeBRO is a replacement for the market maker. It
      provides liquidity to cryptocurrency projects by submitting
      the bid and ask limit orders on a cryptocurrency exchange.
      We are exceptionally skilled at creating markets for utility
      tokens and cryptocurrencies. While most crypto market
      makers are too expensive to be viable, we value building
      long-term, sustainable relationships with our clients.<br/>
      Our technology has always given us an advantage. Our
      market maker algorithm strives to maximize market
      liquidity while automatically managing the order book. We
      are well-known among the masses for offering the most
      cost-effective and simple-to-use solution.
      </p>
      
      
 
      
        </div>
      
        </div>
      
 
   

<div class="row align-items-center">
<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 justify-content-between align-items-center">
<div class="layouthomepagr layoutpagr">
    <h2 style={{textAlign: 'center'}}>TradeBRO Market Making Program</h2>
    <span style={{textAlign: 'center',width: '100%',display: 'block'}}>...</span>
  
    </div>
<div className='row'>

<div class="col-xs-12 col-sm-6 col-md-6">
    <div className='abtusdv'>
        <div className='boxefg'>
            <span>1.</span>
        </div>
        <div className='boxefg'>
        <div className='layout-bx futurefin' style={{alignItems:'flex-start'}}>
        Token companies can reap the
following benefits by partnering
with us
</div>
        </div>
    
    </div>
  </div>
  <div class="col-xs-12 col-sm-6 col-md-6">
  <div className='abtusdv'>
        <div className='boxefg'>
            <span>2.</span>
        </div>
        <div className='boxefg'>
        <div className='layout-bx futurefin' style={{alignItems:'flex-start'}}>
        Mobile Kiosks for Market Making
Necessities
</div>
        </div>
    
    </div>
  </div>
  <div class="col-xs-12 col-sm-6 col-md-6">
    <div className='abtusdv'>
        <div className='boxefg'>
            <span>3.</span>
        </div>
        <div className='boxefg'>
        <div className='layout-bx futurefin' style={{alignItems:'flex-start'}}>
        More accessible listing on major
crypto exchanges as credible
crypto exchanges is more likely
to list a token on their platform if
a market maker is dedicated to
providing liquidity 24/7.
</div>
        </div>
    
    </div>
  </div>
  <div class="col-xs-12 col-sm-6 col-md-6">
  <div className='abtusdv'>
        <div className='boxefg'>
            <span>4.</span>
        </div>
        <div className='boxefg'>
        <div className='layout-bx futurefin' style={{alignItems:'flex-start'}}>
        Increasing popularity, more
people are trading the token and
the possibility of listing on more
extensive exchanges, generating
greater interest and conversation
about the token project.
</div>
        </div>
    
    </div>
  </div>


</div>


</div>
</div>  





<div style={{float:"left", width:"100%", position:"relative"}}>
  <div className='layouthomepagr layoutpagr'>
  <h2 style={{textAlign: 'center', marginBottom:"1rem"}}>FAQs</h2>
  </div>
<Sectioneight />
</div>



<div class="row align-items-center" style={{paddingTop:"2rem"}}>
<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 justify-content-between align-items-center">
<div class="layouthomepagr layoutpagr">
    <h2 style={{textAlign: 'center',paddingBottom:"1rem"}}>Contact US</h2>
    <span className='cnTcfspan'>Please take a moment to get in touch, we will get back to you shortly.</span>   
    </div>

    <div class="row">
      <div class="contactus col-md-6 col-lg-6 order-2">
 
      <form ref={form} className="row" onSubmit={sendEmail}>
        <div className='col-md-6'>
        <label>Name</label>
      <input type="text" name="name" />

      <label>Email</label>
      <input type="email" name="email"/>


      <label>Company Name</label>
      <input type="text" name="cpname" />


      <label>Website</label>
      <input type="text" name="website" />


        </div>

      <div className='col-md-6'>
      <label>Contact Number</label>
      <input type="number" name="phone" />
      
      <label>Message</label>
      <textarea name="message" />
      <input type="submit" value="Send" />
      </div>
    </form>
      </div>
<div className='col-md-6 col-lg-6 order-1'>
<img src={contactradekiya} className="contactrade25"/>
</div>

</div>

          </div>
      </div>
     
     </div>
     
      </section> 
    
    </>

    
  )
}

export default TradeBRO

