import React, {useRef} from 'react'
//import { Carousel } from '3d-react-carousal';
import './NewandMedia.css'
//import MyComponent from '3d-react-carousel'
//import '3d-react-carousel/dist/index.css'
import Carousel from 'react-elastic-carousel'

const NewandMedia = () => {


   const items = [
      {
       url:require('../assets/images/timesOfIndia.png'),
       href: 'https://timesofindia.indiatimes.com/business/india-business/top-10-most-influential-business-leaders-of-2022-by-the-indian-alert/articleshow/95666278.cms'

      },
      {
       url: require('../assets/images/decconH.png'),
       href: 'https://www.deccanherald.com/brandspot/pr-spot/future-s-finance-a-proof-of-authority-blockchain-platform-forays-into-nfts-and-metaverse-1097479.html'

      },     
      {
       url:require('../assets/images/fullresolution.png'),
       href: 'https://edtimes.in/futures-finance-a-new-age-blockchain-platform-is-utilizing-the-poa-algorithm-to-deliver-scalable-real-life-solutions/'
 
      },     
      {
       url: require('../assets/images/ani_logo.jpg'),
       href: 'https://www.aninews.in/news/business/business/futures-finance-launches-its-proprietary-crypto-wallet-the-fufi-edge-available-for-download-on-the-android-play-store20220412152908/'
      },  
      {
         url:require('../assets/images/sugerMint.png'),
         href: 'https://sugermint.com/durga-prasad-tripathi/'
      }  
    ]
    const carouselRef = useRef(null);
    let resetTimeout;
    
  return (
    <>
      
<Carousel
className='Carousel45'
 ref={carouselRef}
 pagination={false}
 enableAutoPlay={true}
 showArrows={true}
 autoPlaySpeed={4000}
 itemsToShow={4}
 isRTL={false}
 onNextEnd={({ index }) => {
     clearTimeout(resetTimeout);
     resetTimeout = setTimeout(() => {
         carouselRef?.current?.goTo(0);
     }, 4000); // same time
 }}>
{items.map(item =>
<div key={item.id}>
   <div className='bidBoxify'>
   <div className='bidBoxify25'>
      <a href={item.href} target='_blank'>
         <img src={item.url} alt="items" />
      <h5>{item.id}</h5>
 <p>{item.title}</p>
 </a>
   </div>
   </div>
</div>
   )}
</Carousel>
    </>
  )
}

export default NewandMedia





