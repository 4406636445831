import React from 'react'
import image28 from '../assets/images/image28.gif'


const FufiedgeWallet = () => {
	
  return (
    <>
      <section class="section gradient-banner" style={{background:"#fff",padding:"50px 0 170px"}}>

        <div class="containerxd-xl">
          <div class="row align-items-center">
            <div class="col-xs-6 col-sm-6 col-md-7 col-lg-6 order-2 order-md-1 text-center text-md-left">
              
            <div className='rounded shadow p-52 bg-white' style={{backgroundColor:"#f3f3f3"}}>
              <h1 class="text-blck font-weight-bold mb-4">
              FuFi Edge Wallet
              </h1>
              <p class="text-blck mb-5">
              FUFIEdge is a proprietary wallet for all FUFI holders. You can enter FutureFi’s ecosystem via FUFIEdge, it is vital for transactions, swaps, and keeping your funds safe.  It is one of the best cryptocurrency wallets occupied with a wide range of advanced features more importantly it is one of the safest crypto wallets in the multi-currency wallet categories.
              </p>

              <p class="text-blck mb-5">
<ul className='fgtThj'>
  <li>It enables users to send, receive, stake, and hold FUFI and FUFI assets in one secure house.</li>
  <li>It acts as a source for becoming a SID member or a stakeholder on the FUFI network.</li>
  <li>One can easily access multiple networks from one crypto wallet.</li>
  <li>It is crucial for deploying FEP20 tokens via smart contracts.</li>
</ul>
       
              </p>
              <div className='buttontrd'>
              <a href="https://firebasestorage.googleapis.com/v0/b/tradekia-2c69c.appspot.com/o/app-release.apk?alt=media&token=81e7ea7f-9c2b-4221-8910-7f786c972e1f" 
target="_blank">FuFi Edge Wallet</a>
                </div>
            </div>
            </div>



            <div class="col-xs-6 col-sm-6 col-md-5 col-lg-6 text-center order-1 order-md-2">
              <div className='image28'>
              <img class="img-fluid" src={image28} alt="screenshot" />
              </div>
            </div>
          </div>
        </div>
      </section>




    </>
  )
}

export default FufiedgeWallet
