import React from 'react'

import './timeline.css'

const Timeline = () => {
  return (
    <section class="section gradient-banner" style={{ padding: "1px 0 10px" }}>
      <div className='timeline-continer' style={{ marginTop: "3em" }}>
        <ul class="timeline">
          <li class="timeline-event">
            <label class="timeline-event-icon"></label>
            <div class="timeline-event-copy">
              <p class="timeline-event-thumbnail">
                <div class="time">
                  <span class="mnths">January</span>
                  <span class="mntimes">2022</span>
                </div>
              </p>
              <p class="content">
                <span class="naes"></span>
                <span class="namxd header_text" >
                  <ul>
                    <li>Conceptualization of FutureFi BlockChain</li>
                  </ul>
                </span>
              </p>
            </div>
          </li>
          <li class="timeline-event">
            <label class="timeline-event-icon"></label>
            <div class="timeline-event-copy">
              <p class="timeline-event-thumbnail">
                <div class="time"><span class="mnths">February</span>
                  <span class="mntimes">2022</span></div>
              </p>
              <p class="content">
                <span class="naes"></span>
                <span class="namxd header_text">
                  <ul>
                    <li>FutureFi BlockChain & Smart Contract live.</li>
                    <li>FUFI coins launched.</li>
                    <li>Listing of FUFI coins and Latoken.</li>
                    <li>Phase 1 Development of FUFI Edge wallet.</li>
                  </ul>
                </span></p>
            </div>
          </li>
          <li class="timeline-event">
            <label class="timeline-event-icon"></label>
            <div class="timeline-event-copy">
              <p class="timeline-event-thumbnail">
                <div class="time"><span class="mnths">March</span>
                  <span class="mntimes">2022</span></div>
              </p>
              <p class="content">
                <span class="naes"></span><span class="namxd header_text">
                  <ul>
                    <li>
                      Listing of FUFI coin on Indoex.
                    </li>
                  </ul></span></p>
            </div>
          </li>


          <li class="timeline-event">
            <label class="timeline-event-icon"></label>
            <div class="timeline-event-copy">
              <p class="timeline-event-thumbnail">
                <div class="time"><span class="mnths">April</span><span class="mntimes">2022</span></div>
              </p>
              <p class="content">
                <span class="naes"></span><span class="namxd header_text">
                  <ul>
                    <li>Development of TradeBRO.</li>
                    <li>FutureFi learn Platform: <a href="https://fufi.info/" target="_blank"><b>FutureFi Blog live.</b></a></li>
                    <li>Development begin for SID module.</li>
                  </ul></span></p>
            </div>
          </li>


          <li class="timeline-event">
            <label class="timeline-event-icon"></label>
            <div class="timeline-event-copy">
              <p class="timeline-event-thumbnail">
                <div class="time"><span class="mnths">May</span><span class="mntimes">2022</span></div>
              </p>
              <p class="content">
                <span class="naes"></span><span class="namxd header_text">
                  <ul>
                    <li>Phase 2 Development FUFI edge wallet.</li>
                    <li>TradeBRO development is completed.</li>
                    <li>SID module live.</li>
                  </ul></span></p>
            </div>
          </li>


          <li class="timeline-event">
            <label class="timeline-event-icon"></label>
            <div class="timeline-event-copy">
              <p class="timeline-event-thumbnail">
                <div class="time"><span class="mnths">June</span>
                  <span class="mntimes">2022</span></div>
              </p>
              <p class="content">
                <span class="naes"></span><span class="namxd header_text">
                  <ul>
                    <li>Integration of TradeBRO with multiple exchanges (BigOne, LaToken, BiBox etc)</li>
                    <li>FUFI Edge Wallet V.2 Live</li>
                    <li>FUFI Burning Event Live</li>
                  </ul></span></p>
            </div>
          </li>


          <li class="timeline-event">
            <label class="timeline-event-icon"></label>
            <div class="timeline-event-copy">
              <p class="timeline-event-thumbnail">
                <div class="time"><span class="mnths">July</span>
                  <span class="mntimes">2022</span></div>
              </p>
              <p class="content">
                <span class="naes"></span><span class="namxd header_text">
                  <ul>
                    <li>Phase 1 Development of TradeKIA
                      Exchange</li>
                    <li>SID Module Live V.1</li>

                  </ul></span></p>
            </div>
          </li>


          <li class="timeline-event">
            <label class="timeline-event-icon"></label>
            <div class="timeline-event-copy">
              <p class="timeline-event-thumbnail">
                <div class="time"><span class="mnths">August</span>
                  <span class="mntimes">2022</span></div>
              </p>
              <p class="content">
                <span class="naes"></span><span class="namxd header_text">
                  <ul>
                    <li>TrdaeKia Exchange V.1 Live</li>
                    <li>TradeOX Phase 1 Development</li>

                  </ul></span></p>
            </div>
          </li>


          <li class="timeline-event">
            <label class="timeline-event-icon"></label>
            <div class="timeline-event-copy">
              <p class="timeline-event-thumbnail">
                <div class="time"><span class="mnths">September</span>
                  <span class="mntimes">2022</span></div>
              </p>
              <p class="content">
                <span class="naes"></span><span class="namxd header_text">
                  <ul>
                    <li>SID Module Live V.2</li>
                  </ul></span></p>
            </div>
          </li>


          <li class="timeline-event">
            <label class="timeline-event-icon"></label>
            <div class="timeline-event-copy">
              <p class="timeline-event-thumbnail">
                <div class="time"><span class="mnths">October</span>
                  <span class="mntimes">2022</span></div>
              </p>
              <p class="content">
                <span class="naes"></span><span class="namxd header_text">
                  <ul>
                    <li>Development on Stablecoin FUSD </li>
                    <li>Phase 1 of the development of project R33
                    </li>
                  </ul></span></p>
            </div>
          </li>




          <li class="timeline-event">
            <label class="timeline-event-icon"></label>
            <div class="timeline-event-copy">
              <p class="timeline-event-thumbnail">
                <div class="time"><span class="mnths">November</span>
                  <span class="mntimes">2022</span></div>
              </p>
              <p class="content">
                <span class="naes"></span><span class="namxd header_text">
                  <ul>
                    <li>TradeKIA Exchange introduced Proof-Of-Reserve (PoR)</li>
                    <li>Airdrop of FUSD</li>
                    <li>Phase 1 development of CryptoxIN</li>
                  </ul></span></p>
            </div>
          </li>




          <li class="timeline-event">
            <label class="timeline-event-icon"></label>
            <div class="timeline-event-copy">
              <p class="timeline-event-thumbnail">
                <div class="time"><span class="mnths">December</span>
                  <span class="mntimes">2022</span></div>
              </p>
              <p class="content">
                <span class="naes"></span><span class="namxd header_text">
                  <ul>
                    <li>Launch of an R33 Module</li>
                    <li>Launch of FUSD (FutureFi’s stablecoin)</li>
                  </ul></span></p>
            </div>
          </li>



          <li class="timeline-event">
            <label class="timeline-event-icon"></label>
            <div class="timeline-event-copy">
              <p class="timeline-event-thumbnail">
                <div class="time"><span class="mnths">January</span>
                  <span class="mntimes">2023</span></div>
              </p>
              <p class="content">
                <span class="naes"></span><span class="namxd header_text">
                  <ul>
                    <li>Phase 2 of CryptoxIN development</li>
                    <li>Airdrop of CIN</li>
                  </ul></span></p>
            </div>
          </li>




          <li class="timeline-event">
            <label class="timeline-event-icon"></label>
            <div class="timeline-event-copy">
              <p class="timeline-event-thumbnail">
                <div class="time"><span class="mnths">February</span>
                  <span class="mntimes">2023</span></div>
              </p>
              <p class="content">
                <span class="naes"></span><span class="namxd header_text">
                  <ul>
                    <li>CryptoxIN (Beta) V.1 Live</li>
                  </ul></span></p>
            </div>
          </li>

          <li class="timeline-event">
            <label class="timeline-event-icon"></label>
            <div class="timeline-event-copy">
              <p class="timeline-event-thumbnail">
                <div class="time"><span class="mnths">March</span>
                  <span class="mntimes">2023</span></div>
              </p>
              <p class="content">
                <span class="naes"></span><span class="namxd header_text">
                  <ul>
                  <li>CryptoxIN (Beta) V.2 Live</li>
                  <li>Tradeox V.1 Live</li>
                  <li>TrdaeKia Exchange V.2 Live</li>
                  </ul></span></p>
            </div>
          </li>




        </ul>
      </div>
    </section>
  )
}

export default Timeline
